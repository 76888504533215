// create admin nav

import { Link } from "react-router-dom";
import React, {useState} from "react";
import useAuth from "../../hooks/useAuth";
import sksLogo from '../../sks.svg';


function AdminNav({ currentUser }) {
	const { logout } = useAuth();
	const [showDropdown, setShowDropdown] = useState(false);

	return (
		<div className="fixed top-0 w-full z-50 font-source-sans-pro">
			<nav className="flex justify-between w-full text-white py-3 px-8 bg-black" style={{ height: '50px' }}> {/* set to 71 to macth sks site*/}
				<div className="flex mr-12 -ml-3">
					<a href="https://www.sks-rehab.com/de/" target="_blank" rel="noopener noreferrer">
						<img src={sksLogo} alt="SKS REHAB Logo" style={{ width: 'auto', height: '30px', marginTop: '0px' }} />
					</a>
				</div>
				<ul className="flex pt-1 justify-center flex-grow" style={{ fontSize: '17px' }}>
					<li className="mx-4">
						<Link to="/a/article-list" className="hover:text-red-600 p-2 rounded uppercase">
							Artikel
						</Link>
					</li>
					<li className="mx-4">
						<Link to="/a/category-list" className="hover:text-red-600 p-2 rounded uppercase">
							Kategorien
						</Link>
					</li>
					<li className="mx-4">
						<Link to="/a/product-list" className="hover:text-red-600 p-2 rounded uppercase">
							Produkte
						</Link>
					</li>
					<li className="mx-4">
						<Link to="/a/user-list" className="hover:text-red-600 p-2 rounded uppercase">
							Nutzer
						</Link>
					</li>
				</ul>
				<div className="pt-1" style={{ fontSize: '17px' }}>
					<Link to="/a/account" className="hover:text-red-600 p-2 rounded uppercase">
						{currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : 'ACCOUNT'}
					</Link>
				</div>
				<div className="relative ml-10 mr-1" style={{ fontSize: '17px' }} onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
					<div className="cursor-pointer pt-1 rounded uppercase z-10 flex hover:text-red-600">DE</div>
				</div>
			</nav>
			<div className="w-full bg-red-600" style={{ height: '3px' }}></div>
		</div>
	);

}

export default AdminNav;
