import React, { useEffect, useRef, useState } from 'react';
import 'tailwindcss/tailwind.css';

import {
    updateModule,
    updatePage,
    getPages,
    getModules,
    getProducts, getPagesHead
} from "../configuration/create/ModuleApi";
import UserPageIndicator from "./elements/UserPageIndicator";
import UserModule from "./fields/UserModule";
import { useParams } from "react-router-dom";
import { request } from "../../../axios_helper";



const NewEditConfiguration = () => {
    const [modules, setModules] = useState([]);

    const [configuration, setConfiguration] = useState(null);

    const [pages, setPages] = useState([]);
    const [pagesHead, setPagesHead] = useState(null);

    const [currentPageId, setCurrentPageId] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const [orderedPages, setOrderedPages] = useState([]);

    const [products, setProducts] = useState([]);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [productId, setProductId] = useState(null);

    const { configurationId } = useParams();

    //const productId = 403;

    useEffect(() => {
        const doEffect = async () => {

            const loadedConfiguration = await request("GET", `api/v1/configuration/${configurationId}`)
                .then(
                    (response) => {
                        return response.data;
                    }
                )
                .catch(
                    (error) => {
                        console.log(error);
                    }
                );

            setConfiguration(loadedConfiguration);

            setProductId(loadedConfiguration.productId)

            console.log("Configuration ID: ", configurationId)
            console.log("Product ID: ", loadedConfiguration.productId)


            const loadedPages = await getPages();
            const loadedModules = await getModules();
            const loadedProducts = await getProducts();
            const loadedPagesHead = await getPagesHead(loadedConfiguration.productId);

            setModules(loadedModules);
            setPages(loadedPages);
            setPagesHead(loadedPagesHead);
            setProducts(loadedProducts);

            const savedCurrentPageId = localStorage.getItem('currentPageId');

            const validPageId = loadedPages.some((page) => page.id === savedCurrentPageId);

            if (savedCurrentPageId && validPageId) {
                setCurrentPageId(savedCurrentPageId);
                const initialPage = loadedPages.find((page) => page.id == savedCurrentPageId);
                setCurrentPage(initialPage);
                const currProduct = loadedProducts.find((product) => product.id == loadedConfiguration.productId);
                setCurrentProduct(currProduct)
            }
            else {
                setCurrentPageId(loadedPagesHead);
                const initialPage = loadedPages.find((page) => page.id == loadedPagesHead);
                setCurrentPage(initialPage);
                const currProduct = loadedProducts.find((product) => product.id == loadedConfiguration.productId);
                setCurrentProduct(currProduct)
            }
        };
        doEffect();

    }, []);


    useEffect(() => {
        if (currentPageId) {
            localStorage.setItem('currentPageId', currentPageId);
        }
    }, [currentPageId]);



    useEffect(() => {
        const page = pages.find((p) => p.id === currentPageId);
        setCurrentPage(page);
    }, [currentPageId, pages]);
    const goToNextPage = () => {
        if (currentPage.next) {
            setCurrentPageId(currentPage.next);
        }
    };
    const goToPreviousPage = () => {
        if (currentPage.prev) {
            setCurrentPageId(currentPage.prev);
        }
    };
    const handleKeyDown = (event) => {
        if (event.key === 'ArrowRight') {
            goToNextPage();
        } else if (event.key === 'ArrowLeft') {
            goToPreviousPage();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    });

    if (!currentPage) {
        return <div>Loading...</div>;
    }

    const updateCurrentPage = (updatedPage) => {
        setCurrentPage(updatedPage);
        setPages((prevPages) =>
            prevPages.map((page) => (page.id === updatedPage.id ? updatedPage : page))
        );
        updatePage(updatedPage);
    };

    return (
        <div className="relative">
            <div className="absolute left-10 top-0 text-2xl inline-block uppercase text-black">
                {currentProduct.name}
            </div>
            <UserPageIndicator currentPageId={currentPageId} setCurrentPageId={setCurrentPageId} pages={pages} pagesHead={pagesHead}/>
            <div className="flex justify-center ">
                <button
                    onClick={goToPreviousPage}
                    disabled={!currentPage.prev}
                    className="fixed left-2 top-1/2 hover:text-red-700 text-red-600 disabled:text-white "
                    style={{fontSize: '50px', zIndex: 10 }}
                >
                    &#x276E; {/* Left arrow */}
                </button>
                <button
                    onClick={goToNextPage}
                    disabled={!currentPage.next}
                    className="fixed right-2 top-1/2 hover:text-red-700 text-red-600 disabled:text-white "
                    style={{fontSize: '50px', zIndex: 10 }}
                >
                    &#x276F; {/* Right arrow */}
                </button>
            </div>
            <div className="flex justify-between min-h-full px-7 relative">
                <Column
                    modules={modules} setModules={setModules}
                    pages={pages} setPages={setPages}
                    column="left"
                    headId={currentPage.leftHead}
                    setHeadId={(newHeadId) => {
                        updateCurrentPage({
                            ...currentPage,
                            leftHead: newHeadId,
                        });
                    }}
                    setOtherHead={(newHeadId) => {
                        updateCurrentPage({
                            ...currentPage,
                            rightHead: newHeadId,
                        });
                    }}
                    currentPageId={currentPageId}
                />
                <div className=" bg-gray-400 my-5 min-h-full shadow shadow-lg w-[1px] flex-shrink-0">
                </div>
                <Column modules={modules} setModules={setModules}
                        pages={pages} setPages={setPages}
                        column="right"
                        headId={currentPage.rightHead}
                        setHeadId={(newHeadId) => {
                            updateCurrentPage({
                                ...currentPage,
                                rightHead: newHeadId,
                            });
                        }}
                        setOtherHead={(newHeadId) => {
                            updateCurrentPage({
                                ...currentPage,
                                leftHead: newHeadId,
                            });
                        }}
                        currentPageId={currentPageId}
                />
            </div>
        </div>

    );
}

const Column = ({ modules, setModules, pages, setPages, column, headId, setHeadId, setOtherHead, currentPageId, }) => {
    const [active, setActive] = useState(false);

    const saveModule = (id, content) => {
        updateModule(id, content);
        setModules((modules) => modules.map(module => module.id === id ? { ...module, content } : module));
    }

    const filteredModules = modules
        .filter(
            (module) =>
                module.column == column && module.page == currentPageId
        )
        .sort((a, b) => a.idx - b.idx);


    const renderModules = (filteredModules, headId) => {
        // Create a map for quick access to modules by their ID
        const moduleMap = filteredModules.reduce((acc, module) => {
            acc[module.id] = module;
            return acc;
        }, {});

        const orderedModules = filteredModules.sort((a, b) => a.order - b.order);

        // Render the modules in the correct order
        return orderedModules.map((module) => (
                <UserModule
                    key={module.id}
                    {...module}
                    saveModule={saveModule}
                />
        ));
    };

    return (
        <div className="w-1/2 shrink-0 flex flex-col h-fit">
            <div
                className={`h-full min-h-10 w-full transition-colors ${active ? "bg-gray-100/50" : "bg-gray-100/0"}
				grid grid-cols-1 auto-rows-[44lvh] gap-0 grow`}
            >
                {
                    renderModules(filteredModules, headId)
                }
            </div>
        </div>
    );
}

export default NewEditConfiguration;

