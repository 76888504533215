// InputTextArea.js
import React from 'react';

const InputTextArea = ({ label, value, onChange }) => (
    <div className="mb-4">
        <label className="block text-gray-700 font-medium">
            {label}
            <textarea
                className="mt-2 block w-full rounded-md border p-2"
                value={value}
                onChange={onChange}
            ></textarea>
        </label>
    </div>
);

export default InputTextArea;
