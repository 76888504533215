import React, { useEffect, useState, useContext } from 'react';
//import { ArticleContext } from "../../../../App";
import ModuleInputText from "./ModuleInputText";
import ModuleInputCheckbox from "./ModuleInputCheckbox";
import InputTextArea from "../field/InputTextArea";
import {EditableTextArea} from "../Module";
import ModuleArticleSelect from "./ModuleArticleSelect";
import ModuleDeleteButton from "./ModuleDeleteButton";
import ArticleFieldModal from "./ArticleFieldModal";
import SliderFieldModal from "./SliderFieldModal";
import { FaEdit } from "react-icons/fa";


const ModuleSliderField = ({ fieldData, onSaveField, deleteField }) => {

    const [showSliderModal, setShowSliderModal] = useState(false);

    return (
        <>
            <div className="rounded border h-full z-10 shadow-md bg-white hover:bg-gray-100 p-1 text-xs overflow-hidden">
                {/*<ModuleDeleteButton
                    deleteField={() => deleteField(fieldData.id)}
                    className="absolute top-0 right-0 m-2"
                />*/}
                <div className="flex content-center">
                    <div className="flex flex-col w-1/2">
                        <div className="overflow-hidden">
                            <EditableTextArea
                                initialText={fieldData.options.title ? fieldData.options.title : "Slider-Field Title"}
                                onSave={(t) =>
                                    onSaveField(fieldData.id, { ...fieldData.options, title: t })
                                }
                                classes={"text-sm font-semibold"}
                                maxLength={70}
                            />
                        </div>
                        {/*<div className="mb-4">
                            <EditableText
                                initialText={fieldData.options.description ? fieldData.options.description : "Slider-Field Description"}
                                onSave={(d) => onSaveField(fieldData.id, { ...fieldData.options, description: d })}
                                classes={"text-xs "}
                            />
                        </div>*/}
                    </div>
                    <div className="flex items-center gap-2 ml-auto h-10">
                        <div className="content-center">
                            <div className="text-xs font-semibold">
                                ArticleNr
                            </div>
                            <div className="text-xs">
                                {fieldData.options.articleId ? fieldData.options.articleId : "-"}
                            </div>
                        </div>
                        <div className="content-center">
                            <div className="text-xs font-semibold">
                                MinVal
                            </div>
                            <div className="text-xs">
                                {fieldData.options.minVal ? fieldData.options.minVal : "0"}
                            </div>
                        </div>
                        <div className="content-center">
                            <div className="text-xs font-semibold">
                                MaxVal
                            </div>
                            <div className="text-xs">
                                {fieldData.options.maxVal ? fieldData.options.maxVal : "100"}
                            </div>
                        </div>
                        <div className="content-center">
                            <div className="text-xs font-semibold">
                                Unit
                            </div>
                            <div className="text-xs">
                                {fieldData.options.unit ? fieldData.options.unit : "-"}
                            </div>
                        </div>
                        <div className="content-center">
                            <div className="text-xs font-semibold">
                                ShowPrice
                            </div>
                            <div className="text-xs">
                                {fieldData.options.showPrice ? "Yes" : "No"}
                            </div>
                        </div>
                        <button className="relative mt-4">
                            <FaEdit size={14} onClick={() => setShowSliderModal(true)} />
                        </button>
                    </div>
                </div>

            </div>
            {
                showSliderModal &&
                <SliderFieldModal fieldData={fieldData} articles={[]} categories={[]} onClose={() => setShowSliderModal(false)} onSaveField={onSaveField}/>
            }
        </>
    );
};

export default ModuleSliderField;
