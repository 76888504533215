import { Link } from "react-router-dom";
import React, { useState } from "react";
import { deleteAuthToken, request } from "../../axios_helper";
import useAuth from "../../hooks/useAuth";
import sksLogo from '../../sks.svg';



function ClientNav({ currentUser }) {
	const { setAuth, logout } = useAuth();
  const [showDropdown, setShowDropdown] = useState(false);
	return (
	  <div className="fixed top-0 w-full z-50 font-source-sans-pro">
		<nav className="flex justify-between w-full text-white py-3 px-8 bg-black" style={{ height: '50px' }}>
				<div className="flex mr-12 -ml-3">
					<a href="https://www.sks-rehab.com/de/" target="_blank" rel="noopener noreferrer">
						<img src={sksLogo} alt="SKS REHAB Logo" style={{ width: 'auto', height: '30px', marginTop: '0px' }} />
					</a>
				</div>
				<div className="flex-grow text-center pt-1" style={{ fontSize: '17px' }}>
					<Link to="/u/configurationsNew" className="hover:text-red-600 p-2 rounded uppercase">
						Konfigurationen
					</Link>
				</div>
				<div className="flex-grow text-center pt-1" style={{ fontSize: '17px' }}>
					<Link to="/u/configurations" className="hover:text-red-600 p-2 rounded uppercase">
						Konfigurationen (Alt)
					</Link>
				</div>

				<div className="pt-1" style={{ fontSize: '17px' }}>
					<Link to="/u/account" className="hover:text-red-600 p-2 rounded uppercase">
						{currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : 'ACCOUNT'}
					</Link>
				</div>
				<div className="relative ml-10 mr-1" style={{ fontSize: '17px' }} onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
					<div className="cursor-pointer pt-1 rounded uppercase z-10 flex hover:text-red-600">DE</div>
				</div>
			</nav>
		  	<div className="w-full bg-red-600" style={{ height: '3px' }}></div>
		</div>
	);
}

export default ClientNav;

