import React, { useContext, useEffect, useState } from 'react';
import ArticleField from './ArticleField';  // import your field components
import TextField from './TextField';
import SelectionField from './SelectionField';
import Field from "./Field";
import FieldModal from "../../../../modals/FieldModal";
import DraggableField from "./DraggableField";
import { newField } from "./util";
import axios from "axios";
import { ArticleContext } from "../../../../App";
import { FieldsContext } from "../ProductCreation";
import { request } from "../../../../../axios_helper";
import DeleteButton from "./DeleteButton";
import InputText from "./InputText";
import InputTextArea from './InputTextArea';


const SectionField = ({ handleInputChange, onSubmit, fieldData, deleteField}) => {
	//console.log("inputFieldData: ", inputFieldData)
	const { fields, setFields } = useContext(FieldsContext);
	const { articles, categories} = useContext(ArticleContext)
	const [sectionTitle, setSectionTitle] = useState('');
	const [sectionDescription, setSectionDescription] = useState('');
	const [isNumbered, setIsNumbered] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [localFields, setLocalFields] = useState([]);


	const moveField = (fromIndex, toIndex) => {
		const updatedFields = [...fields];
		const [movedField] = updatedFields.splice(fromIndex, 1);
		updatedFields.splice(toIndex, 0, movedField);
		setFields(updatedFields);
	};

	useEffect(() => {
		setLocalFields(fields.filter(field => field.parentId == fieldData.id).sort((a,b) => a.position - b.position));
	}, [fields]);

	const handleAddField = async (type) => {
		const newFieldData = newField(fieldData, type, localFields.length)
		const response = await request("POST", "/api/v1/field", newFieldData)
		const updatedChildren = [...fieldData.options.fields, response.data.id]
		handleInputChange("fields", updatedChildren)
		setFields([...fields, response.data])
		console.log("response", response.data)
		setShowModal(false);

	};

	const handleSectionSubmit = () => {
		const options = {
			"title": sectionTitle,
			"description": sectionDescription,
			"isNumbered": isNumbered,
			"fields": fields,
		};
		const newField = {
			parentId: fieldData.id,
			productId: fieldData.productId,
			pageId: fieldData.pageId,
			name: "",
			type: "section",
			options: JSON.stringify(options)
		}
		onSubmit(options);
	};


	return (
		<div className="p-4 relative">
			<DeleteButton deleteField={deleteField} />
			<div className="bg-white rounded-md shadow-md p-6">
				<h2 className="text-2xl mb-4">Section</h2>
				<h3>{sectionTitle || "New Section"}</h3>
				<InputText
					label="Section Title"
					type="text"
					value={fieldData.options.title}
					onChange={(e) => handleInputChange("title", e.target.value)}
				/>
				<InputTextArea
					label="Section Description"
					value={fieldData.options.description}
					onChange={(e) => handleInputChange("description", e.target.value)}
				/>
				<label>
					Numbered:
					<input type="checkbox" checked={fieldData.options.isNumbered} onChange={(e) => handleInputChange("isNumbered", !fieldData.options.isNumbered)} />
				</label>

				<div className="mt-4" style={{marginLeft: '20px'}}>
					{localFields.map((field, index) => (
						<Field key={field.id} fieldDataInput={field} />
					))}
				</div>

				<div
					className="flex justify-center items-center bg-gray-200 cursor-pointer hover:bg-gray-300 rounded border border-gray-600"
					style={{ height: '100px' }}
					onClick={() => setShowModal(true)}
				>
					<span className="text-xl text-gray-600">Add field</span>
				</div>

				<FieldModal
					show={showModal}
					onClose={() => setShowModal(false)}
					onSubmit={(fieldType) => handleAddField(fieldType)}
				/>

			</div>
		</div>
	);
};

export default SectionField;
