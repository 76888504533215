import React, { useRef, useState, useCallback, useEffect, useContext } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import _, { debounce } from "lodash";
import axios from "axios";
import SectionField from "./field/SectionField";
import { FieldsContext } from "./ProductCreation";
import Field from "./field/Field";
import { request } from "../../../../axios_helper";

const DraggableSection = ({ section, index, moveSection }) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: 'SECTION',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }

      moveSection(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'SECTION',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
      <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }} className="mb-2">
        <SectionField {...section} />
        <div className="bg-gray-300 h-0.5 my-4"></div> {/* This is the separator */}
      </div>
  );
};

const PageCreation = ({articles, categories, pageDataInput, productId, onPageDataChange}) => {
  const [pageTitle, setPageTitle] = useState(pageDataInput ? pageDataInput.title : '');
  const [pageDescription, setPageDescription] = useState(pageDataInput ? pageDataInput.description : '');
  const [sections, setSections] = useState([]);
  const [pageData, setPageData] = useState(pageDataInput || {title: '', description: '', productId: productId});
  const [showModal, setShowModal] = useState(false);

  const { fields, setFields } = useContext(FieldsContext);
  const [localFields, setLocalFields] = useState([]);

  useEffect(() => {
    console.log("Change in fields or pageData: ", pageData.id);
    const newFields = fields.filter(field => field.parentId == null && field.pageId === pageData.id).sort((a,b) => a.position - b.position);
    console.log("Old fields: ", localFields);
    console.log("New fields: ", newFields);
    setLocalFields(newFields);
  }, [fields, pageData]);



  // This is the debounced function which will be called
  // 300ms after the last call to it (if it's called repeatedly in quick succession).

  // A handler for input changes.
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Update the local state immediately.
    setPageData(prevData => ({ ...prevData, [name]: value }));

    // Then update the parent state, but with a delay (and only if no subsequent changes within 300ms).
    debouncedSave({ ...pageData, [name]: value });
  };

  useEffect(() => {
    // Check if pageDataInput has changed significantly before updating pageData
    setPageData({ ...pageDataInput});
    console.log(`new pages data input: ${JSON.stringify(pageDataInput)}`);
  }, [pageDataInput]);


  const debouncedSave = useCallback(debounce((data) => {
    console.log("debounce")
    submit(data);
  }, 300), []);

  const submit = async (page) => {
    if (page.id) {
      console.log("Before post with id: " + JSON.stringify(page));
      request("PUT", `/api/v1/page/${page.id}`, page);
    }
    //else {
    //  console.log("Product pre sending" +JSON.stringify(page))
    //  response = await request("POST", "/api/v1/page", page)
    //      .then(response => {
    //        console.log("Product post sending" +JSON.stringify(response.data))
    //          setPageData(prevProduct => {
    //            const updatedPage = { ...prevProduct, ["id"]: response.data.id }
    //            return updatedPage
    //          });
    //      })
    //}
    console.log("submit page " + JSON.stringify(page));
  }

  // Now, handlePageUpdate only updates local state
  function handlePageUpdate(property, newValue) {
    setPageData(prevPage => {
      const updatedPage = { ...prevPage, [property]: newValue }
      debouncedSave(updatedPage)
      onPageDataChange(updatedPage)
      return updatedPage
    });
  }

  // Debounced call to update parent state
  const debouncedPageUpdate = useCallback(debounce((updatedPageData) => {
    onPageDataChange(updatedPageData);
  }, 300), []);

  //useEffect(() => {
  //  // Update parent state with debounced call
  //  debouncedPageUpdate(pageData);
  //}, [debouncedPageUpdate]);


  const moveSection = (dragIndex, hoverIndex) => {
    const dragSection = sections[dragIndex];
    const newSections = [...sections];
    newSections.splice(dragIndex, 1);
    newSections.splice(hoverIndex, 0, dragSection);
    setSections(newSections);
  };

  const handleAddSection = async (sectionData) => {
    const options = {
      title: "",
      description: "",
      isNumbered: false,
      fields: []
    }
    const newField = {
      parentId: null,
      productId: pageData.productId,
      pageId: pageData.id,
      position: localFields.length,
      name: "",
      type: "section",
      options: options
    }
    request("POST", "/api/v1/field", newField)
        .then(response => {
            console.log("Field added", response.data);
            const updatedFields = [...localFields, response.data];
            setFields(updatedFields);
        });
      //if (response.status === 200) {
      //  const createdField = response.data;
      //  setFields(prevFields => [...prevFields, createdField]);
      //  // Optionally update the global state/context as well if needed
      //}
  };
  return (
      <div className="p-4">
        <div className="bg-white rounded-md shadow-md p-6">
          <div className="mb-4">
            <label className="block text-gray-700 font-medium">
              Page Title
              <input
                  className="mt-2 block w-full rounded-md border p-2"
                  type="text"
                  value={pageData.title || ''}
                  onChange={(e) => handlePageUpdate("title", e.target.value)}
              />
            </label>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-medium">
              Page Description
              <textarea
                  className="mt-2 block w-full rounded-md border p-2"
                  value={pageData.description || ''}
                  onChange={(e) => handlePageUpdate("description", e.target.value)}
              ></textarea>
            </label>
          </div>

          <div className="mt-4 mb-2">
            <strong>Sections:</strong>
            {localFields.map((localField, index) => {
              // Render each section. Note: We should wrap this in a DraggableSection component for drag-n-drop.
              return <Field key={localField.id} index={index} fieldDataInput={localField} moveSection={moveSection}/>
            })}
          </div>

          <div
              className="flex justify-center items-center bg-gray-200 cursor-pointer hover:bg-gray-300 rounded border border-gray-600"
              style={{ height: '100px' }}
              onClick={() => handleAddSection({articles, categories})}
          >
            <span className="text-4xl text-gray-600">Add Section</span>
          </div>
        </div>
      </div>
  );
};

export default PageCreation;
