import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import useAuth from "../hooks/useAuth";

function LandingPage() {
	const { auth, loading }  = useAuth();
	const [user, setUser] = useState(null);
	//const [loading, setLoading] = useState(true);

	useEffect(() => {
		console.log("User in LandingPage: ", auth);
	} , [auth]);

	return (
		auth && !loading &&
		<div>
			{auth?.token
				? auth?.role === "ADMIN"
					? <Navigate to="/a" />
					: <Navigate to="/u" />
				: <Navigate to="/login" />}
			}
		</div>
	);
}

export default LandingPage;
