import { Link, Route, Routes, Outlet} from "react-router-dom";
import React, {useContext, useEffect, useState } from "react";
import ArticleList from "../../ArticleList";
import Configurations from "./Configurations";
import ComponentTest from "./ComponentTest";
import ClientNav from "../../navs/ClientNav";
import PageHandler from "./elements/PageHandler";
import SelectProduct from "./SelectProduct";
import EditConfiguration, {ConfigurationContext} from "./EditConfiguration";
import { request } from "../../../axios_helper";
import AccountPage from "./AccountPage";
import AcceptInvitation from "../../AcceptInvitation";
import {useOnMountUnsafe} from "../../../UseOnMountUnsafe";

export const ConfigurationTitleContext = React.createContext();


function UserWindow() {

	const [currentUser, setCurrentUser] = useState(null);

	const [product, setProduct] = useState(null);

    useOnMountUnsafe(() => {
        request("GET", "/api/v1/account/current")
            .then(response => {
                setCurrentUser(response.data);
            })
    });


	return (
		<div className="flex">
      <ConfigurationTitleContext.Provider value={{setTitleProduct: setProduct}} >
        <ClientNav currentUser={currentUser} product={product} />
        <main className="flex-1 p-4" style={{ paddingTop: '58px' }}>
          <Outlet />
        </main>
      </ConfigurationTitleContext.Provider>
		</div>
	)
}

export default UserWindow
