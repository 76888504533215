import React, { useState, useEffect, useContext } from 'react';
import { ArticleContext } from "../../../../App";
import field from "./Field";
import ArticleSelect from "./ArticleSelect";
import DeleteButton from "./DeleteButton";
import InputText from './InputText'; // Import InputText component
import InputCheckbox from './InputCheckbox';
import InputTextArea from "./InputTextArea"; // Import InputCheckbox component


const TextField = ({ onSubmit, onChange, fieldData, handleInputChange, deleteField}) => {
	const { articles, categories} = useContext(ArticleContext)
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [filteredArticles, setFilteredArticles] = useState(articles);
	const [selectedArticle, setSelectedArticle] = useState(null);
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [prefix, setPrefix] = useState('');
	//const [hasUnit, setHasUnit] = useState(false);
	const [unit, setUnit] = useState('');

	const hasUnit = fieldData.options.unit != null



	useEffect(() => {
		if (selectedCategory) {
			const filtered = articles.filter(article => article.categoryId === selectedCategory);
			setFilteredArticles(filtered);
		} else {
			setFilteredArticles(articles);
		}
	}, [selectedCategory, articles]);

	const handleTextFieldSubmit = () => {
		const options = {
			articleId: selectedArticle,
			userFacingTitle: title,
			userFacingDescription: description,
			prefix,
			unit: hasUnit ? unit : null,
		};
		const field = {
			name: 'art_'+options.userFacingTitle.toLocaleLowerCase().substring(0,4),
			type: 'text',
			options: JSON.stringify(options)
		}
		onSubmit(field);
	};

	return (
		<div className="p-4 relative">
			<DeleteButton deleteField={deleteField} />
			<div className="bg-white rounded-md shadow-md p-6">
				<h2 className="text-2xl mb-4">Text</h2>

				<ArticleSelect
					articles={articles}
					categories={categories}
					handleInputChange={handleInputChange}
					articleId={fieldData.options.articleId} />

				<InputText
					label="Title"
					type="text"
					value={fieldData.options.title}
					onChange={(e) => handleInputChange("title", e.target.value)}
				/>
				<InputTextArea
					label="Description"
					value={fieldData.options.description}
					onChange={(e) => handleInputChange("description", e.target.value)}
				/>

				<InputText
					label="Prefix Text"
					type="text"
					value={fieldData.options.prefix}
					onChange={(e) => handleInputChange("prefix", e.target.value)}
				/>

				<InputCheckbox
					label="Show Price"
					checked={fieldData.options.showPrice}
					onChange={() => handleInputChange("showPrice", !fieldData.options.showPrice)}
				/>
				<InputCheckbox
					label="Show Unit"
					checked={hasUnit}
					onChange={() => handleInputChange("unit", hasUnit ? null : "")}
				/>

				{hasUnit && (
					<InputText
						label="Unit"
						type="text"
						value={fieldData.options.unit}
						onChange={(e) => handleInputChange("unit", e.target.value)}
					/>
				)}


			</div>
		</div>
	);
};

export default TextField;
