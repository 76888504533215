import { useOnMountUnsafe } from "../UseOnMountUnsafe";
import React, { useContext, useState } from "react";
import { AuthContext } from "./AuthProvider";
import { request } from "../axios_helper";
import {Link} from "react-router-dom";

function AdminAccountPage({ }) {

    const { auth, logout } = useContext(AuthContext);

    const [currentUser, setCurrentUser] = useState(null);

    useOnMountUnsafe(() => {
        request("GET", "/api/v1/account/current")
            .then(response => {
                setCurrentUser(response.data);
            })
    });


    return (
        <div className="font-source-sans-pro p-2">
            <h1 className="text-3xl font-bold">Account</h1>
            <p className="text-xl font-semibold">Hier kannst du deine Daten einsehen.</p>
            <hr className="my-4" />
            <h2 className="text-xl font-semibold">Nutzername</h2>

            <p className="text-xl">{auth.username}</p>
            <h2 className="text-xl font-semibold mt-2">Vorname</h2>
            <p className="text-xl">{auth.firstName}</p>
            <h2 className="text-xl font-semibold mt-2">Nachname</h2>
            <p className="text-xl">{auth.lastName}</p>
            <h2 className="text-xl font-semibold mt-2">E-Mail</h2>
            <p className="text-xl">{auth.email}</p>

            <div className="mt-8">
                <button className="bg-green-500 hover:bg-green-700 text-white rounded-lg p-2 w-40 block  mb-4">
                    <Link to="/u/account" className="p-2 rounded">
                        Konfigurator
                    </Link>
                </button>
            </div>

            <div>
                <button
                    className="bg-red-600 text-white rounded-lg hover:bg-red-700 p-2 w-40 block"
                    onClick={() => logout()}
                >
                    Logout
                </button>
            </div>

        </div>

    );
}

export default AdminAccountPage;