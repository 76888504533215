import React, { useState, useContext, useEffect } from 'react';
import { ArticleContext } from "../../../../App";
import { FieldsContext } from "../ProductCreation";
import DeleteButton from "./DeleteButton";
import FieldModal from "../../../../modals/FieldModal";
import Field from "./Field";
import { newField } from "./util";
import { request } from "../../../../../axios_helper";
import InputText from './InputText'; // Import InputText component
import InputCheckbox from './InputCheckbox';
import InputTextArea from "./InputTextArea"; // Import InputCheckbox component


const SelectionField = ({ onSubmit, onChange, fieldData, handleInputChange, deleteField }) => {
	const { articles, categories} = useContext(ArticleContext)
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [selectedFields, setSelectedFields] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');
	const [selectedCategory, setSelectedCategory] = useState(null);
	const { fields, setFields } = useContext(FieldsContext);
	const [showModal, setShowModal] = useState(false);
	const [localFields, setLocalFields] = useState([]);



	const handleFieldSubmit = () => {
		const options = {
			userFacingTitle: title,
			userFacingDescription: description,
			selectedFields: selectedFields
		};
		const field = {
			name: 'art_'+options.userFacingTitle.toLocaleLowerCase().substring(0,4),
				type: 'selection',
			options: JSON.stringify(options)
		}
		onSubmit(field);
	};

	const handleFieldChange = (fieldId) => {
		const prevSelectedFields = fieldData.options.selectedFields
		handleInputChange("selectedFields",
			prevSelectedFields.includes(fieldId)
				? prevSelectedFields.filter((field) => field !== fieldId)
				: [...prevSelectedFields, fieldId]
		);
	};

	const filteredFields = fields.filter(field => {
		// If a category is selected, filter fields based on it
		if (selectedCategory && field.categoryId !== selectedCategory) {
			return false;
		}

		try {
			// If a search term exists, filter fields based on it
			return !(searchQuery && !field.options.title.toLowerCase()
				.includes(searchQuery.toLowerCase()));


		} catch (e) {
			console.log("Search Query", searchQuery);
			console.log("Field", field);
			return false;
		}
	});

	function handleAddField(fieldType) {
		const newFieldData = newField(fieldData, fieldType, fields.length)
		request("POST", "/api/v1/field", newFieldData)
			.then(response => {
				const updatedChildren = [...fieldData.options.selectedFields, response.data.id]
				handleInputChange("selectedFields", updatedChildren)
				setFields([...fields, response.data])
				console.log("response", response.data)
				setShowModal(false);
			});

	}

	useEffect(() => {
		setLocalFields(fields.filter(field => field.parentId == fieldData.id).sort((a,b) => a.position - b.position));
	} , [fields]);

	return (
		<div className="p-4 relative">
			<DeleteButton deleteField={deleteField} />
			<div className="bg-white rounded-md shadow-md p-6">
				<h2 className="text-2xl mb-4">Selection</h2>
				<InputText
					label="Title"
					type="text"
					value={fieldData.options.title}
					onChange={(e) => handleInputChange("title", e.target.value)}
				/>
				<InputTextArea
					label="Description"
					value={fieldData.options.description}
					onChange={(e) => handleInputChange("description", e.target.value)}
				/>

				<InputText
					type="text"
					placeholder="Search fields..."
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
				/>
				<div className="mb-4">
					<label className="block text-gray-700 font-medium">
						Filter by Category
						<select
							className="mt-2 block w-full rounded-md border p-2"
							onChange={(e) => setSelectedCategory(e.target.value)}
						>
							<option value="">All Categories</option>
							{categories.map((category) => (
								<option value={category.id} key={category.id}>
									{category.name}
								</option>
							))}
						</select>
					</label>
				</div>
				<InputCheckbox
					label="Exclusive"
					checked={fieldData.options.isExclusive}
					onChange={(e) => handleInputChange("isExclusive", e.target.checked)}
				/>

				<InputCheckbox
					label="Optional"
					checked={fieldData.options.isOptional}
					onChange={(e) => handleInputChange("isOptional", e.target.checked)}
				/>
				<div className="mt-4" style={{marginLeft: '20px'}}>
					{localFields.map((field, index) => (
						<Field key={field.id} fieldDataInput={field} />
					))}
				</div>
				<div
					className="flex justify-center items-center bg-gray-200 cursor-pointer hover:bg-gray-300 rounded border border-gray-600"
					style={{ height: '100px' }}
					onClick={() => setShowModal(true)}
				>
					<span className="text-xl text-gray-600">Add to selection</span>
				</div>

				<FieldModal
					show={showModal}
					onClose={() => setShowModal(false)}
					onSubmit={(fieldType) => handleAddField(fieldType)}
				/>
			</div>
		</div>
	);
};

export default SelectionField;
