import { useEffect, useState } from "react";
import DeleteModal from "./modals/DeleteModal";
import ArticleModal from "./modals/ArticleModal";
import axios from "axios";
import CategoryModal from "./modals/CategoryModal";
import { request } from "../axios_helper";

function CategoryList({ onArticleClick, onAddArticle, onDeleteCategory}) {

	const [categories, setCategories] = useState([]);
	const [selectedCategoryId, setSelectedCategoryId] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showArticleModal, setShowArticleModal] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState(null);

	const fetchCategories = async () => {
		try {
			request("GET", "/api/v1/category")
				.then(response => {
					setCategories(response.data);
				});
		} catch (error) {
			console.error('An error occurred:', error);
		}
	};

	useEffect(() => {
		fetchCategories();
	}, []);

	const handleArticleClick = (article) => {
		setSelectedCategoryId(article.id);
	};

	const handleDeleteClick = (e, article) => {
		e.stopPropagation(); // Prevent the row from getting selected
		setSelectedCategoryId(article.id);
		setShowDeleteModal(true);
	};

	const handleCloseDeleteModal = () => {
		setShowDeleteModal(false);
	};

	const handleDeleteConfirm = () => {
		request("DELETE", `/api/v1/category/${selectedCategoryId}`)
			.then(response => {
				setCategories(categories.filter(category => category.id !== selectedCategoryId));
				setShowDeleteModal(false);
			} );
	};

	const handleOpenModal = (article) => {
		setSelectedCategory(article);
		setShowArticleModal(true);
	};

	const handleCloseModal = () => {
		setSelectedCategory(null);
		fetchCategories();
		setShowArticleModal(false);
	};

	const handleSubmit = async (formData) => {
		if (selectedCategory) {
			request("PUT", `/api/v1/category/${selectedCategory.id}`, formData)
				.then(response => {
					console.log("response", response.data)
					setCategories(categories.map(category =>
						category.id === selectedCategory.id ? response.data : category
					));
				} );
		}
		else {
			request("POST", "/api/v1/category", formData)
				.then(response => {
					console.log("response", response.data)
					setCategories([...categories, response.data]);
				} );
		}
		handleCloseModal();
	};


	return (
		<div className="p-2 bg-white font-source-sans-pro">
			<h2 className="mb-4 text-3xl font-bold">
				Kategorien
			</h2>
			<table className="min-w-full leading-normal">
				<thead>
				<tr>
					<th className="py-3 px-5 border-b border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
						ID
					</th>
					<th className="py-3 px-5 border-b border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
						Name
					</th>
					<th className="py-3 px-5 border-b border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
						Actions
					</th>
				</tr>
				</thead>
				<tbody>
				{categories.map((category, index) => (
					<tr
						key={index}
						className={`
                ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} 
                ${selectedCategoryId === category.id ? 'bg-lavender' : ''}
              `}
						onClick={() => handleArticleClick(category)}
					>
						<td className="py-3 px-5 border-b text-sm">
							{category.id}
						</td>
						<td className="py-3 px-5 border-b text-sm">
							{category.name}
						</td>
						<td className="py-3 px-5 border-b text-sm">
							<button
								className="text-blue-500 hover:text-blue-700 pr-12"
								onClick={() => handleOpenModal(category)}
							>
								Edit
							</button>
							<button className="bg-red-500 text-white px-4 py-2 rounded" onClick={(e) => handleDeleteClick(e, category)}>
								Delete
							</button>
						</td>
					</tr>
				))}
				</tbody>
			</table>
			<div className="flex justify-end p-4">
				<button
					className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
					onClick={() => handleOpenModal(null)}
				>
					+
				</button>
			</div>
			<DeleteModal
				show={showDeleteModal}
				onClose={handleCloseDeleteModal}
				onDelete={handleDeleteConfirm}
			/>

			<CategoryModal
				show={showArticleModal}
				onClose={handleCloseModal}
				onSubmit={handleSubmit}
				category={selectedCategory}
			/>

		</div>
	);
}

export default CategoryList;
