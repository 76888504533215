import React, { useEffect, useState } from "react";
import { request } from "../../axios_helper";

function ArticleModal({ show, onClose, onSubmit, article, categories }) {

	const [selectedCategory, setSelectedCategory] = useState(0);
	const [selectedFile, setSelectedFile] = useState(null);
	const [imagePath, setImagePath] = useState(null);

	const emptyArticle = {
		internalId: "",
		description1: "",
		description2: "",
		categoryId: "",
	}

	// Use state to manage the form inputs
	const [formData, setFormData] = useState(emptyArticle);

	useEffect(() => {
		console.log("Article prop in modal:", article);
		setFormData(article ? article : emptyArticle);
		if (article && article.imagePath) {
			setImagePath(article.imagePath);
		}
		else {
			setImagePath(null);
			setSelectedFile(null);
		}
	}, [article]);

	useEffect(() => {
		if(categories.length > 0) {
			setSelectedCategory(categories[0].id);
		}
	}, [categories]);

	useEffect(() => {
		if (imagePath) {
			request("GET", imagePath, null, null, "blob")
				.then((response) => {
					console.log("Image fetched" + imagePath);
					setSelectedFile(response.data)
				});
		}
	}, [imagePath]);

	if (!show) return null;

	// Update state whenever an input changes
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const handleDeleteImage = () => {
		setSelectedFile(null);
		setImagePath(null);
		setFormData({ ...formData, imagePath: null });
	};


	return (
		<div className="fixed z-10 inset-0 flex items-center justify-center">
			<div className="bg-gray-700 opacity-75 absolute inset-0" onClick={onClose}></div>
			<div className="modal-content bg-white rounded p-4 z-20 relative">
				<h2 className="text-xl mb-2">Create/Edit Article</h2>
				<form onSubmit={(e) => { e.preventDefault(); onSubmit(formData, selectedFile); }}>
					<label className="block mb-2">Internal ID</label>
					<input
						type="text"
						name="internalId"
						value={formData.internalId}
						onChange={handleChange}
						className="mb-4 p-2 border rounded"
					/>
					<label className="block mb-2">Artikelbezeichnung 1</label>
					<input
						type="text"
						name="description1"
						value={formData.description1}
						onChange={handleChange}
						className="mb-4 p-2 border rounded"
					/>
					<label className="block mb-2">Artikelbezeichnung 2</label>
					<input
						type="text"
						name="description2"
						value={formData.description2}
						onChange={handleChange}
						className="mb-4 p-2 border rounded"
					/>
					<label className="block mb-2">Category ID</label>
					<select
						className="mt-2 block w-full rounded-md border p-2"
						value={formData.categoryId}
						name="categoryId"
						onChange={(e) => handleChange(e)}
					>
						{categories.map(category => (
							<option key={category.id} value={category.id}>
								{category.name}
							</option>
						))}
					</select>

					<label className="block mb-2">Image</label>
					<input type="file" onChange={handleFileChange} />
					{selectedFile && (
						<div className="image-preview">
							<img
								src={URL.createObjectURL(selectedFile)}
								alt="Preview"
								className="w-1/2 h-1/2"
							/>
							<button
								type="button"
								className="bg-red-500 text-white px-4 py-2 rounded mt-2"
								onClick={handleDeleteImage}
							>
								Delete Image
							</button>
						</div>
					)}


					<div className="flex justify-end mt-4">
						<button type="submit" className="bg-green-500 text-white px-4 py-2 rounded mr-2">Submit</button>
						<button type="button" className="bg-gray-300 text-black px-4 py-2 rounded" onClick={() => {
							onClose();
							setFormData(emptyArticle)}
						}>Cancel</button>
					</div>
				</form>
			</div>
		</div>
	);
}

export default ArticleModal;
