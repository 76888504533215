import React, { useEffect, useState, useContext } from 'react';
import axios from "axios";
import { ArticleContext } from "../../../../App";
import ArticleSelect from "./ArticleSelect";
import DeleteButton from "./DeleteButton";
import InputText from "./InputText";
import InputCheckbox from "./InputCheckbox";
import InputTextArea from "./InputTextArea";

const ArticleField = ({ onSubmit, onChange, fieldData, handleInputChange, deleteField }) => {
	const { articles, categories} = useContext(ArticleContext)
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [filteredArticles, setFilteredArticles] = useState(articles);
	const [selectedArticle, setSelectedArticle] = useState(null);
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [showPrice, setShowPrice] = useState(false); // New state for show price checkbox




	useEffect(() => {
		if (selectedCategory) {
			const filtered = articles.filter(article => article.categoryId === selectedCategory);
			setFilteredArticles(filtered);
		} else {
			setFilteredArticles(articles);
		}
	}, [selectedCategory, articles]);

	const handleArticleSubmit = () => {
		const options = {
			articleId: selectedArticle,
			userFacingTitle: title,
			userFacingDescription: description,
			showPrice: showPrice
		};
		const field = {
			name: 'art_'+options.userFacingTitle.toLocaleLowerCase().substring(0,4),
			type: 'article',
			options: JSON.stringify(options)
		}
		onSubmit(field);
	};


	return (
		<div className="p-4 relative">
			<DeleteButton deleteField={deleteField} />
			<div className="bg-white rounded-md shadow-md p-6">

				<h2 className="text-2xl mb-4">Article</h2>

				<ArticleSelect
					articles={articles}
					categories={categories}
					handleInputChange={handleInputChange}
					articleId={fieldData.options.articleId} />

				<InputText
					label="Title"
					type="text"
					value={fieldData.options.title}
					onChange={(e) => handleInputChange("title", e.target.value)}
				/>
				<InputTextArea
					label="Description"
					value={fieldData.options.description}
					onChange={(e) => handleInputChange("description", e.target.value)}
				/>

				<InputCheckbox
					label="Show Price"
					checked={fieldData.options.showPrice}
					onChange={() => handleInputChange("showPrice", !fieldData.options.showPrice)}
				/>
			</div>

		</div>
	);
};


export default ArticleField;
