import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import React from "react";

function ExcelModal({show, onClose, onSubmit, countries}) {
	const [excelFile, setExcelFile] = useState(null);
	const [typeError, setTypeError] = useState(null);
	const [selectedCountry, setSelectedCountry] = useState(0);

	useEffect(() => {
		if (countries.length > 0) {
			setSelectedCountry(countries[0].id);
		}
	}, [countries]);

	const handleExcelFile = (e) => {
		let file = e.target.files[0];
		const allowedTypes = [
			'application/vnd.ms-excel',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			'text/csv',
			'application/csv',
		];
		if (file && allowedTypes.includes(file.type)) {
			console.log("File:", file);
			console.log("File type:", file.type);
			setTypeError(null);
			const reader = new FileReader();
			reader.readAsBinaryString(file);
			reader.onload = (e) => {
				setExcelFile(e.target.result)
			}
		} else {
			console.log("No file selected");
			setTypeError("Please select an Excel file.");
			setExcelFile(null);
		}
	}

	const onExcelSubmit = (e) => {
		let priceList = null;
		e.preventDefault();
		const properties = ["Artikelnummer", "Artikelbezeichnung 1", "Artikelbezeichung 2", "Preis"];
		if (excelFile) {
			const workbook = XLSX.read(excelFile, { type: "binary" });
			const worksheet = workbook.Sheets[workbook.SheetNames[0]];
			const data = XLSX.utils.sheet_to_json(worksheet, { raw: true });
			//remove tabS from each property

			data.forEach((row) => {
				Object.keys(row).forEach((key) => {
					const newKey = key.replace(/\t/g, "");
					row[newKey] = row[key];
					if (key !== newKey) {
						delete row[key];
					}
				})
			});
			//check if has required fields
			if ((() => {
				for (let i = 0; i < properties.length; i++) {
					if (!data[0].hasOwnProperty(properties[i])) {
						console.log("Missing property: " + properties[i]);
						return true;
					}
				}
				return false;
			})()) {
				setTypeError("Please select an Excel file with the required fields: Artikelnummer, Artikelbezeichnung 1," +
					" Artikelbezeichung 2, Preis");
				//console.log("Excel Data:" + JSON.stringify(data[0]));
				let returnString = "";
				Object.keys(data[0]).forEach((key) => {
					if (!properties.includes(key)) {
						returnString += key + ", ";
					}
				});
				console.log(returnString);
				return;
			}
			const dataToSubmit = data.map((row) => {
				return {
					internalId: row["Artikelnummer"],
					description1: row["Artikelbezeichnung 1"],
					description2: row["Artikelbezeichung 2"],
					categoryId: null
				}
			})
			if (data[0].hasOwnProperty("Preis")) {
				priceList = data.map((row) => {
					if (typeof row["Preis"] === "number") {
						return {
							price: row["Preis"],
							internalId: row["Artikelnummer"]
						}
				} else {
						return {
							price: Number(row["Preis"].replace(",", "").replace("/\D/g", "")),
							internalId: row["Artikelnummer"]
						}
					}
				});
			}
			onSubmit(dataToSubmit, priceList, selectedCountry);

		}
	}

	if (!show) return null;

	return (
		<div className="fixed z-10 inset-0 flex items-center justify-center">
			<div className="bg-gray-700 opacity-75 absolute inset-0" onClick={onClose}></div>
			<div className="modal-content bg-white rounded p-4 z-20 relative">
				<h2 className="text-xl mb-2">Upload Excel</h2>
				<form onSubmit={onExcelSubmit}>
					<select className="mt-2 block w-full rounded-md border p-2" value={selectedCountry} onChange={(e) => setSelectedCountry(e.target.value)}>
						{countries.map(country => (
							<option key={country.id} value={country.id}>
								{country.name}
							</option>
						))}
					</select>
					<div className="flex-col space-y-2">
						<input type="file" required onChange={handleExcelFile} />
						{typeError && <p className="text-red-500">{typeError}</p>}
					</div>
					<button type="submit" className="bg-green-500 p-3 rounded">Upload Excel</button>
				</form>
			</div>
		</div>
	);
}

export default ExcelModal;
