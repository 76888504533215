import UserModuleArticleField from "./UserModuleArticleField";
import React, {useContext, useEffect, useState} from "react";
import UserModuleTextField from "./UserModuleTextField";
import UserModuleSliderField from "./UserModuleSliderField";
import {ConfigurationContext} from "../EditConfiguration";

const UserModuleField = ({ fieldDataInput, onSaveField, selection }) => {

    const type = fieldDataInput.type;
    const [fieldData, setFieldData] = useState(fieldDataInput)

    const [value, setValue] = useState(null);

    const handleFieldChange = (value) => {
        setValue(value);
        onSaveField(fieldData.id, value);
    }

    const fieldComponentMap = {
        "article": UserModuleArticleField,
        "text": UserModuleTextField,
        "slider": UserModuleSliderField
    };

    const FieldComponent = fieldComponentMap[type];
    if (FieldComponent) {
        return (
            <div className="grid grid-cols-1 h-full auto-rows-[6.5lvh] gap-2">
                <div className={`relative z-10 rounded  bg-white border-gray-300 p-1 text-xs overflow-hidden
                ${selection ? "border hover:border-gray-500" : ""}`}>
                    <div className="flex content-center">
                        <FieldComponent
                            fieldData={fieldData}
                            value={value}
                            handleFieldChange={handleFieldChange}
                        />
                    </div>
                </div>
            </div>
        );
    }
    return null;
};

export default UserModuleField;