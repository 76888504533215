import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { request } from "../axios_helper";
import { useState } from "react";

function AcceptInvitation() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [invitation, setInvitation] = useState(null);
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const nav = useNavigate();

  function consumeInvite() {
	  if (!passwordMatch()) {
		  alert("Passwords do not match");
		  return;
	  }
		// Send a request to the backend to accept the invitation
	  request("POST", `api/v1/auth/invite/accept?token=${token}`, {
			  username: username,
			  firstName: firstName,
			  lastName: lastName,
			  email: email,
			  password: password
		  })
	  		  .then(response => {
				  if (response.status === 200) {
	  				  alert("Invitation accepted");
	  			  }
	  		  });
	  nav("/");
  }

  const passwordMatch = () => {
	  return password === passwordRepeat;
  }

  return (
	<div className="font-source-sans-pro">
		<h1>Accepting invitation</h1>
		<h2>Invitation ID: {token}</h2>
		<form>
			<label className="block mb-2">Benutzername</label>
			<input name="username" onChange={(e) => setUsername(e.target.value)} type="text" className="mt-2 block rounded-md border p-2" required />
			<label className="block mb-2">Vorname</label>
			<input name="firstName" onChange={(e) => setFirstName(e.target.value)} type="text" className="mt-2 block rounded-md border p-2" required />
			<label className="block mb-2">Nachname</label>
			<input name="lastName" onChange={(e) => setLastName(e.target.value)} type="text" className="mt-2 block rounded-md border p-2" required />
			<label className="block mb-2">E-Mail</label>
			<input name="email" onChange={(e) => setEmail(e.target.value)} type="email" className="mt-2 block rounded-md border p-2" required />
			<label className="block mb-2">Passwort</label>
			<input name="password" onChange={(e) => setPassword(e.target.value)} type="password" className="mt-2 block rounded-md border p-2" required />
			<label className="block mb-2">Passwort wiederholen</label>
			<input name="passwordRepeat" onChange={(e) => setPasswordRepeat(e.target.value)} type="password" className="mt-2 block rounded-md border p-2" required />
			{!passwordMatch() && passwordRepeat != "" && <p className="text-red-500">Passwords do not match</p>}
			<button onClick={consumeInvite}>Accept</button>
		</form>
	</div>
  );
}

export default AcceptInvitation;
