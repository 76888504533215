import React, { useEffect, useState } from "react";

function ArticleModal({ show, onClose, onSubmit, category }) {

	// Use state to manage the form inputs
	const [formData, setFormData] = useState({
		name: "",
	});

	useEffect(() => {setFormData(category ? category : {name: ""})},
			[category])

	if (!show) return null;

	// Update state whenever an input changes
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	return (
		<div className="fixed z-10 inset-0 flex items-center justify-center">
			<div className="bg-gray-700 opacity-75 absolute inset-0" onClick={onClose}></div>
			<div className="modal-content bg-white rounded p-4 z-20 relative">
				<h2 className="text-xl mb-2">Create/Edit Article</h2>
				<form onSubmit={(e) => { e.preventDefault(); onSubmit(formData); }}>
					<label className="block mb-2">Name</label>
					<input
						type="text"
						name="name"
						value={formData.name}
						onChange={handleChange}
						className="mb-4 p-2 border rounded"
					/>
					<div className="flex justify-end mt-4">
						<button type="submit" className="bg-green-500 text-white px-4 py-2 rounded mr-2">Submit</button>
						<button type="button" className="bg-gray-300 text-black px-4 py-2 rounded" onClick={onClose}>Cancel</button>
					</div>
				</form>
			</div>
		</div>
	);
}

export default ArticleModal;
