// InputText.js
import React from 'react';

const InputText = ({ label, type, placeholder, value, onChange }) => (
    <div className="mb-4">
        <label className="block text-gray-700 font-medium">
            {label}
            <input
                className="mt-2 block w-full rounded-md border p-2"
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            />
        </label>
    </div>
);

export default InputText;
