import React, { useContext, useEffect, useState } from "react";
import { request } from "../../../axios_helper";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../AuthProvider";

function getNewFieldValue(field) {
	if (field.type === "text") {
		return "";
	} else if (field.type === "slider") {
		return field.options.min;
	} else if (field.type === "selection") {
		return [];
	} else if (field.type === "article") {
		return "";
	}
}

function generateConfigJson(product) {
	return product.fields.map(field => {
		return {
			fieldId: field.id,
			fieldValue: getNewFieldValue(field)
		};
	});
}

function SelectProduct({ }) {
	const [products, setProducts] = useState([]);
	const [selectedProduct, setSelectedProduct] = useState(null);

	const {auth} = useContext(AuthContext);

	const navigate = useNavigate();

	useEffect(() => {
		request("GET", "/api/v1/product")
			.then(response => {
				setProducts(response.data.sort((a, b) => a.id - b.id));
			});
	}, []);

	function finalizeSelection() {
		if (auth === null || !auth?.token || selectedProduct === null) {
			return;
		}

		const newConfig = {
			accountId: auth.id,
			productId: selectedProduct,
			title: "",
			description: "",
			clientName: "",
			configJson: JSON.stringify(generateConfigJson(products.find(p => p.id === selectedProduct)))
		};

		console.log("newConfig", newConfig);

		request("POST", `/api/v1/configuration`, newConfig)
			.then(response => {
				console.log("Configuration created");
				navigate(`/u/configurations/${response.data.id}`);
			});
	}

	return (
		<div>
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
				{products.map(product => (
					<div key={product.id}
						 className={`border-2 rounded-lg p-4 shadow-lg hover:shadow-xl transition-shadow duration-300 h-80 ${selectedProduct === product.id ? 'border-red-500' : 'border-gray-300'}`}
						 onClick={() => setSelectedProduct(product.id)}>
						<div className="mt-2">
							<h3 className="text-lg font-semibold">{product.name}</h3>
							{/* Include additional product details here */}
						</div>
						<div className="mt-2">
							<h3 className="block text-gray-700 font-medium">{product.description}</h3>
							{/* Include additional product details here */}
						</div>
					</div>
				))}
			</div>
			<div className="flex justify-center mt-4">
				<button
					onClick={finalizeSelection}
					disabled={selectedProduct === null}
					className={`rounded-full flex items-center justify-center text-white disabled:bg-gray-300 ml-2 ${selectedProduct !== null ? 'bg-red-600' : 'bg-gray-300'}`}
					style={{ width: '50px', height: '50px', fontSize: '24px' }}
				>
					&#x2192; {/* Right arrow */}
				</button>
			</div>
		</div>
	);
}

export default SelectProduct;

export { generateConfigJson, getNewFieldValue };



