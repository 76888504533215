export function newField(parentFieldData, type, position) {
	switch (type) {
		case "selection":
			return newSelectionField(parentFieldData);
		case "slider":
			return newSliderField(parentFieldData);
		case "text":
			return newTextField(parentFieldData);
		case "article":
			return newArticleField(parentFieldData);
		case "section":
			return newSectionField(parentFieldData);

	}
}


function newArticleField (parentFieldData, position)  {
	const opts = {
		articleId: null,
		title: "",
		description: "",
		showPrice: false
	};
	const newField = {
		parentId: parentFieldData.id,
		productId: parentFieldData.productId,
		pageId: parentFieldData.pageId,
		name: "",
		position: position,
		type: "article",
		options: opts
	}
	return newField;
}

function newSelectionField (parentFieldData, position)  {
	const opts = {
		title: "",
		description: "",
		selectedFields: []
	};
	const newField = {
		parentId: parentFieldData.id,
		productId: parentFieldData.productId,
		pageId: parentFieldData.pageId,
		name: "",
		position: position,
		type: "selection",
		options: opts
	}
	return newField;
}

function newTextField (parentFieldData, position)  {
	const opts = {
		articleId: null,
		title: "",
		description: "",
		prefix: "",
		unit: null,
		showPrice: false
	};
	const newField = {
		parentId: parentFieldData.id,
		productId: parentFieldData.productId,
		pageId: parentFieldData.pageId,
		name: "",
		position: position,
		type: "text",
		options: opts
	}
	return newField;
}

function newSliderField (parentFieldData, position)  {
	const opts = {
		articleId: null,
		title: "",
		description: "",
		min: 0,
		max: 100,
		stepCount: 0,
		intervalStep: 0,
		unit: null,
		showPrice: false
	};
	const newField = {
		parentId: parentFieldData.id,
		productId: parentFieldData.productId,
		pageId: parentFieldData.pageId,
		name: "",
		position: position,
		type: "slider",
		options: opts
	}
	return newField;
}

function newSectionField (parentFieldData, position)  {
	const opts = {
		"title": "",
		"description": "",
		"isNumbered": false,
		"fields": [],
	};
	const newField = {
		parentId: parentFieldData.id,
		productId: parentFieldData.productId,
		pageId: parentFieldData.pageId,
		name: "",
		position: position,
		type: "section",
		options: opts
	}
	return newField;
}
