import { useEffect, useState } from "react";
import DeleteModal from "./modals/DeleteModal";
import ArticleModal from "./modals/ArticleModal";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { request } from "../axios_helper";

function ProductList({ onArticleClick, onAddArticle}) {

	const [products, setProducts] = useState([]);
	const [selectedProductId, setSelectedProductId] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showArticleModal, setShowArticleModal] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [currentUser, setCurrentUser] = useState(null);

	const navigate = useNavigate();

	function onDeleteProduct(id) {
		request("DELETE", `/api/v1/product/${id}`)
			.then(() => {
				console.log("Product deleted");
			});
	}

	function createProduct() {
		let newProduct = {
			name: "",
			countryId: currentUser.countryId,
			["pages"]: [],
			["fields"]: []
		};
		return request("POST", "/api/v1/product", newProduct)
			.then(response => {
				setSelectedProduct(prevProduct => {
					setSelectedProductId(response.data.id)
					return response.data;
				});
				newProduct = response.data;
				return newProduct;
			});
	};

	const handleAddClick = async () => {
		const newProduct = await createProduct();
		setProducts([...products, newProduct]);

	}

	const handleEditClick = (id) => {
		navigate(`/a/product/edit/${id}`)
	}

	const fetchProducts = async () => {
		request("GET", "/api/v1/product")
			.then(response => {
				setProducts(response.data.sort((a, b) => a.id - b.id));
			});
	};

	useEffect(() => {
		fetchProducts();
	}, []);

	const handleArticleClick = (product) => {
		setSelectedProductId(product.id);
	};

	const handleDeleteClick = (e, product) => {
		e.stopPropagation(); // Prevent the row from getting selected
		setSelectedProductId(product.id);
		setShowDeleteModal(true);
	};

	const handleCloseDeleteModal = () => {
		setShowDeleteModal(false);
	};

	const handleDeleteConfirm = () => {
		onDeleteProduct(selectedProductId);
		const newProducts = products.filter(product => product.id !== selectedProductId);
		setProducts(newProducts);
		setShowDeleteModal(false);
	};

	const handleOpenModal = (product) => {
		setSelectedProduct(product);
		setShowArticleModal(true);
	};

	const handleCloseModal = () => {
		setSelectedProduct(null);
		fetchProducts();
		setShowArticleModal(false);
	};

	const handleSubmit = async (formData) => {
		if (selectedProduct) {
			await axios.put(`http://localhost:8080/api/v1/product/updateArticle`, formData);
		}
		else {
			await axios.post('http://localhost:8080/api/v1/product/createArticle', formData);
		}
		handleCloseModal();
	};

	//sort products by id
	useEffect(() => {
		setProducts(products.sort((a, b) => a.id - b.id));
		setCurrentUser(
			request("GET", "/api/v1/account/current")
				.then(response => {
					setCurrentUser(response.data);
				})
		);
	} , []);


	return (
		<div className="p-2 bg-white font-source-sans-pro">
			<h2 className="mb-4 text-3xl font-bold">
				Produkte
			</h2>
			<table className="min-w-full leading-normal">
				<thead>
				<tr>
					<th className="py-3 px-5 border-b border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
						ID
					</th>
					<th className="py-3 px-5 border-b border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
						Name
					</th>
					<th className="py-3 px-5 border-b border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
						Article ID
					</th>
					<th className="py-3 px-5 border-b border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
						Country ID
					</th>
					<th className="py-3 px-5 border-b border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
						Actions
					</th>
				</tr>
				</thead>
				<tbody>
				{products.map((product, index) => (
					<tr
						key={index}
						className={`
                ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} 
                ${selectedProductId === product.id ? 'bg-lavender' : ''}
              `}
						onClick={() => handleArticleClick(product)}
					>
						<td className="py-3 px-5 border-b text-sm">
							{product.id}
						</td>
						<td className="py-3 px-5 border-b text-sm">
							{product.name}
						</td>
						<td className="py-3 px-5 border-b text-sm">
							{product.articleId}
						</td>
						<td className="py-3 px-5 border-b text-sm">
							{product.countryId}
						</td>
						<td className="py-3 px-5 border-b text-sm">
							<button
								className="text-blue-500 hover:text-blue-700 pr-12"
								onClick={() => handleEditClick(product.id)}
							>
								Edit
							</button>
							<button className="bg-red-500 text-white px-4 py-2 rounded" onClick={(e) => handleDeleteClick(e, product)}>
								Delete
							</button>
						</td>
					</tr>
				))}
				</tbody>
			</table>
			<div className="flex justify-end p-4">
				<button
					className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
					onClick={() => handleAddClick()}
				>
					+
				</button>
			</div>
			<DeleteModal
				show={showDeleteModal}
				onClose={handleCloseDeleteModal}
				onDelete={handleDeleteConfirm}
			/>

		</div>
	);
}

export default ProductList;
