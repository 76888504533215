import React, { useState, useContext, useEffect  } from 'react';
import { request, setAuthToken } from "../../../axios_helper";
import { redirect, useNavigate } from "react-router-dom";
import { ArticleContext } from "../../App";
import sksLogo from '../../../sks.svg';
import siegel from '../../../karriere_siegel.svg';
import useAuth from "../../../hooks/useAuth";

function Login() {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [displayError, setDisplayError] = useState(false);

	const { articles, categories, setArticles, setCategories, products, setProducts } = useContext(ArticleContext)

	const { auth, setAuth, loading } = useAuth();

	const nav = useNavigate();

	const fetchArticles = async () => {
		request('GET', '/api/v1/articleCountry/articles')
			.then((response) => {
				setArticles(response.data)
			});
	};

	const fetchCategories = async () => {
		request('GET', '/api/v1/category')
			.then((response) => {
				setCategories(response.data)
			});
	}

	const fetchProducts = async () => {
		request('GET', '/api/v1/product')
			.then((response) => {
				setProducts(response.data)
			});
	}

	const handleSubmit2 = async (event) => {

		event.preventDefault();
		// Handle the login logic here
		request("POST", "/api/v1/auth/login", {
			"username": username,
			"password": password
		}).then((response) => {
			setDisplayError(false);
			setAuthToken(response.data.token);
			fetchArticles().then(r => console.log("Articles fetched"));
			fetchCategories().then(r => console.log("Categories fetched"));
			fetchProducts().then(r => console.log("Products fetched"));
			setAuth(response.data);
		}).catch((error) => {
			setDisplayError(true);
		})
	};

	useEffect(() => {
		if (auth?.token) {
			nav('/choice')
		}
	}, [auth]);

	const handleSubmit = async (event) => {
		const submit = await handleSubmit2(event);
	}

	return (
		<div className="flex flex-col justify-center items-center h-screen bg-skew-gradient">
			<div className="mb-8">
				<img src={sksLogo} alt="SKS REHAB Logo" className="h-10" />
			</div>
			<h2 className="text-4xl inline-block text-white mb-6">
				Konfigurator
			</h2>
			<form className="p-6 bg-white rounded-lg shadow-md w-[300px]" onSubmit={handleSubmit}>
				<div>
					<label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">Benutzername:</label>
					<input
						className="w-full p-2 border border-gray-300 rounded mt-2"
						type="text"
						id="username"
						value={username}
						onChange={(e) => setUsername(e.target.value)}
					/>
				</div>
				<div>
					<label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">Passwort:</label>
					<input
						className="w-full p-2 border border-gray-300 rounded mt-4"
						type="password"
						id="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
				</div>
				<div className="w-full p-2 overflow-hidden">
				{displayError &&
					<div className="text-red-500 mx-2 p-2 break-words overflow-hidden">
					Username or password not found! Try again.
					</div>
				}
				</div>
				<button className="w-full bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4" type="submit">
					Weiter
				</button>
			</form>
			<div className="mt-8">
				<img src={siegel} alt="Siegel" className="h-32" />
			</div>
		</div>
	);
}

export default Login;
