import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BrowserRouter as Router, Link, Route, Routes, Outlet} from "react-router-dom";
import ArticleList from "../../ArticleList";
import ProductList from "../../ProductList";
import ProductCreation from "./create/ProductCreation";
import CategoryList from "../../CategoryList";
import Field from "./create/field/Field";
import PageCreation from "./create/PageCreation";
import React, { useContext, useEffect, useState } from "react";
import { ArticleContext } from "../../App";
import AdminNav from "../../navs/AdminNav";
import UserList from "../../UserList";
import AdminInvitationForm from "../../AdminInvitationForm";
import { request } from "../../../axios_helper";
import { useOnMountUnsafe } from "../../../UseOnMountUnsafe";


function Main() {

	const { articles, categories, setArticles, setCategories, products, setProducts } = useContext(ArticleContext)

	const [selectedArticle, setSelectedArticle] = useState(null);

	const [currentUser, setCurrentUser] = useState(null);

	useOnMountUnsafe(() => {
		request("GET", "/api/v1/account/current")
			.then(response => {
				setCurrentUser(response.data);
			})
	});

	function fetchArticles() {
		request("GET", "api/v1/articleCountry/articles")
			.then(response => {
				setArticles(response.data);
			});
	}

	function fetchCategories() {
		request("GET", "api/v1/category")
			.then(response => {
				setCategories(response.data);
			});
	}

	function fetchProducts() {
		request("GET", "api/v1/product")
			.then(response => {
				setProducts(response.data);
			});
	}

	useOnMountUnsafe(() => {
		fetchArticles();
		fetchCategories();
		fetchProducts();
	});



	return (
					<div className="flex">
						<AdminNav currentUser={currentUser} />
						<main className="flex-1 p-4" style={{ paddingTop: '58px' }}>
						<Outlet />
						</main>
					</div>
	);

}

export default Main;
