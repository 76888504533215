import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

function Missing() {

	const [count, setCount] = useState(3);

	const navigate = useNavigate();

	let interval;

	const redirectCountdown = () => {
		interval = setInterval(() => {
			setCount(oldCount => {
				const newCount = oldCount - 1
				if (newCount === 0) {
					clearInterval(interval);
					navigate('/');
				}
				return newCount;
			});
		}, 1000);
	}

	useEffect(() => {
		redirectCountdown();
		return () => clearInterval(interval);
	}, []);

	return (
		<div>
			<h1>404 - Not Found!</h1>
			<p>Redirecting to home in {count} seconds...</p>
		</div>
	)

}

export default Missing;
