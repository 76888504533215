import React from 'react';

const ModuleInputCheckbox = ({ label, checked, onChange }) => (
    <div className="">
        <label className="flex items-center text-gray-700 font-medium">
            {label}
            <input
                type="checkbox"
                className="ml-4"
                checked={checked}
                onChange={onChange} />
        </label>
    </div>
);

export default ModuleInputCheckbox;