import React from "react";

function DeleteButton ({ deleteField }) {
	return (
		<button
			className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full h-6 w-6 flex items-center justify-center text-xs hover:bg-red-700"
			onClick={deleteField}
		>
			X
		</button>
	)
}

export default DeleteButton;
