import React, { useEffect, useState } from "react";

function ModuleArticleSelect(props) {
    const { articles, categories, handleInputChange, articleId } = props;

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [filteredArticles, setFilteredArticles] = useState(articles);
    const [selectedArticle, setSelectedArticle] = useState(null);


    useEffect(() => {
        if (selectedCategory) {
            const filtered = articles.filter(article => article.categoryId === selectedCategory);
            setFilteredArticles(filtered);
        } else {
            setFilteredArticles(articles);
        }
        setSelectedArticle(articles.find(article => article.id === articleId))
    }, [selectedCategory, articles]);

    useEffect(() => {
        if (articleId) {
            const article = articles.find(article => article.id === articleId);
            if (article) {
                setSelectedCategory(article.categoryId);
            }
        }
    }, [articleId]);


    return (
        <div className="w-full flex flex-col items-start">

            <div className="mb-2 flex justify-between w-full items-center">
                <label className="block text-gray-700 font-medium w-2/3">Category</label>
                <select
                    className="block  ml-auto rounded-md border p-2 w-full"
                    onChange={(e) =>
                        e.target.value === "" ? setSelectedCategory(null) :
                            setSelectedCategory(Number(e.target.value))}>
                    <option value="">Select category</option>
                    {categories.map(category => (
                        <option value={category.id} key={category.id}>
                            {category.name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="flex justify-between w-full items-center">

                <label className="block text-gray-700 font-medium w-2/3">Article</label>
                <select
                    className="block ml-auto rounded-md border p-2 w-full"
                    value={articleId}
                    onChange={(e) => handleInputChange("articleId", Number(e.target.value))}>
                    {selectedArticle ?
                        <option value={articleId}>
                            {selectedArticle.description1}
                        </option> :
                        <option value="">Select article</option>
                    }
                    {filteredArticles.map(article => (
                        <option value={article.id} key={article.id}>
                            {article.description1}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
}

export default ModuleArticleSelect;