
import { useContext, useRef } from "react";

import React, { useMemo } from 'react';
import {EditableTextArea} from "./Module";

const NewPageIndicator = ({ currentPageId, setCurrentPageId, pages, pagesHead, onAddPage }) => {

    const orderedPages = useMemo(() => {
        if (!pages || pages.length === 0 || !pagesHead || !Array.isArray(pages)) {
            return [];
        }

        const orderedPagesList = [];
        let currentId = pagesHead;
        const pageMap = pages.reduce((acc, page) => {
            acc[page.id] = page;
            return acc;
        }, {});

        const visited = new Set();

        while (currentId) {
            // To prevent infinite loops in case of cycles
            if (visited.has(currentId)) {
                console.warn('Cycle detected in pages linked list. Stopping traversal.');
                break;
            }
            visited.add(currentId);

            const page = pageMap[currentId];
            if (!page) {
                console.error(`Page with id ${currentId} not found.`);
                break;
            }

            orderedPagesList.push(page);
            currentId = page.next;
        }

        return orderedPagesList;
    }, [pages, pagesHead]);

    return (
        <div className="flex justify-center items-center w-full mt-2">
            {orderedPages.map((page, idx) => (
                <button
                    key={page.id}
                    className={`mx-1 flex items-center justify-center text-center bg-red-600 hover:bg-red-700 text-white`}
                    style={{
                        width: page.id === currentPageId ? '256px' : '32px',
                        height: '32px',
                        borderRadius: '32px',
                        transition: 'width 0.5s ease',
                    }}
                    onClick={() => setCurrentPageId(page.id)}
                >
                      <span
                          style={{
                              fontSize: '24px',
                              marginRight: page.id === currentPageId ? '8px' : '0',
                          }}
                      >
                        {idx + 1}
                      </span>
                    {page.id === currentPageId && (
                        <span style={{ fontSize: '20px', alignSelf: 'center' }}>

                            <EditableTextArea
                                initialText={page.title ? page.title : "Text-Field Title"}
                                onSave={(t) => (page.id, { ...page.title, title: t }) // this wrong
                                }
                                classes={""}
                                maxLength={20}
                            />
                        </span>
                    )}
                </button>
            ))}
            <button
                className={`mx-1 flex items-center justify-center text-center bg-white border-2 border-red-600 text-red-600 hover:bg-red-100`}
                style={{
                    width: '32px',
                    height: '32px',
                    borderRadius: '32px',
                    transition: 'width 0.5s ease',
                }}
                onClick={onAddPage}
            >
              <span
                  style={{
                      fontSize: '30px',
                      fontWeight: 'normal',
                      color: 'red-600',
                      position: 'relative',
                      bottom: '3px',
                      left: '0.5px'
                  }}
              >
    +
  </span>
            </button>

        </div>
    );
};

export default NewPageIndicator;

