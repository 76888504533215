import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import 'tailwindcss/tailwind.css';
import { FiPlus, FiTrash } from "react-icons/fi";
import { FaFire } from "react-icons/fa";
import { motion } from 'framer-motion';
import Module, {EditableTextArea} from "./Module";
import {
	addMaster,
	addModule,
	getMasters,
	getModules,
	removeModule,
	updateHead,
	updateModule,
	getPages,
	updatePage, addPage, updatePagesHead, getPagesHead, getHeadOfPageColumn, removeFromList, onModulesReordered, getProducts
} from "./ModuleApi";
import { GiHamburgerMenu } from "react-icons/gi";
import emitter from "./eventemitter";
import PageIndicator from "../../client/elements/PageIndicator";
import NewPageIndicator from "./NewPageIndicator";
import { useParams } from "react-router-dom";
import { request } from "../../../../axios_helper";


const FormBuilder = ({product}) => {
	const [modules, setModules] = useState([]);
	const [rightHead, setRightHead] = useState(null);
	const [leftHead, setLeftHead] = useState(null);
	const [dragging, setDragging] = useState(false);
	const [masterModules, setMasterModules] = useState([]);

	const [pages, setPages] = useState([]);
	const [pagesHead, setPagesHead] = useState(null);

	const [currentPageId, setCurrentPageId] = useState(null);
	const [currentPage, setCurrentPage] = useState(null);
	const [orderedPages, setOrderedPages] = useState([]);

	const [products, setProducts] = useState([]);
	const [currentProduct, setCurrentProduct] = useState({});

	const { productId: paramId } = useParams();
	const [productId, setProductId] = useState(paramId);



	const pagesRef = useRef(pages);



	const handleAddPage = async () => {

		const prevPages = pagesRef.current;

		let newPage = {
			prev: null,
			next: null,
			product: productId,
			title: `Page ${pages.length + 1}`,
		};

		const headPageId = await getPagesHead(productId);

		const pagesMap = {};
		prevPages.forEach((page) => {
			pagesMap[page.id] = page;
		});

		let newPages;

		if (prevPages.length === 0 || !headPageId) {
			// No pages exist, newPage becomes the head
			newPage = await addPage(newPage)
			setPagesHead(newPage.id);
			updatePagesHead(productId, newPage.id);
			newPages = [newPage];
		}
		else {
			// Find the head page (page with prev === null)
			const headPage = prevPages.find((page) => page.id === headPageId);
			if (!headPage) {
				throw new Error('Head page not found. Id: ' + headPageId);
			}

			// Traverse to the last page
			let lastPage = headPage;
			while (lastPage.next) {
				lastPage = pagesMap[lastPage.next];
			}
			newPage.prev = lastPage.id;

			newPage = await addPage(newPage)

			// Create an updated last page without mutating the original
			let updatedLastPage = { ...lastPage, next: newPage.id };
			updatedLastPage = await updatePage(updatedLastPage);

			// Update pages array
			const updatedPages = prevPages.map((page) =>
				page.id === lastPage.id ? updatedLastPage : page
			);

			newPages = [...updatedPages, newPage];
		}

		setPages(newPages);



		// Update the currentPageId to the new page
		setCurrentPageId(newPage.id);

		return newPage;
	};




	useEffect(() => {

		const doEffect = async () => {
			const loadedPages = await getPages();
			let loadedPagesHead = await getPagesHead(productId)
			console.log("loadedPagesHead", loadedPagesHead)
			console.log("loadedPages", loadedPages)
			let validPageId;
			const savedCurrentPageId = localStorage.getItem('currentPageId');

			const productPages = loadedPages.filter((page) => page.product == productId);

			if (productPages.length === 0) {
				console.log("no pages")
				let newPage = await handleAddPage();
				loadedPages.push(newPage);
				productPages.push(newPage);
				loadedPagesHead = newPage.id;
				updatePagesHead(productId, loadedPagesHead);
			} else if (!loadedPagesHead && productPages.length > 0) {
				loadedPagesHead = loadedPages[0].id;
				updatePagesHead(productId, loadedPagesHead);
			}


			setPagesHead(loadedPagesHead);
			setPages(productPages);
			validPageId = productPages.some((page) => page.id === savedCurrentPageId);
			if (savedCurrentPageId && validPageId) {
				setCurrentPageId(savedCurrentPageId);
				const initialPage = productPages.find((page) => page.id === savedCurrentPageId);
				setCurrentPage(initialPage);
			}
			else {
				setCurrentPageId(loadedPagesHead);
				const initialPage = productPages.find((page) => page.id === loadedPagesHead);
				setCurrentPage(initialPage);
			}

			// check if current product has pages
			//filter pages by product id
			//if pages exist, set current page to the first page
			//if no pages exist, create a new page and set it as the current page
			//console.log("productPages", productPages)
			//if (productPages.length > 0) {
			//	setCurrentPageId(productPages[0].id);
			//}
			//else {
			//	await handleAddPage();
			//}
			const loadedModules = await getModules(productId);
			setModules(loadedModules);
			const loadedMasters = await getMasters();
			setMasterModules(loadedMasters);
			const loadedProducts = await getProducts();
			setProducts(loadedProducts);
			const currentProduct = loadedProducts.find(product => product.id == productId);
			setCurrentProduct(currentProduct);
			console.log("currentProduct", currentProduct)
		}
		doEffect();

	}, []);


	useEffect(() => {
		if (currentPageId) {
			localStorage.setItem('currentPageId', currentPageId);
		}
	}, [currentPageId]);



	useEffect(() => {
		if (!Array.isArray(pages) || pages.length === 0) return;
		const page = pages.find((p) => p.id === currentPageId);
		setCurrentPage(page);
		pagesRef.current = pages;
	}, [currentPageId, pages]);



	useEffect(() => {
	}, [masterModules])



	const goToNextPage = () => {
		if (currentPage.next) {
			setCurrentPageId(currentPage.next);
		}
	};

	const goToPreviousPage = () => {
		if (currentPage.prev) {
			setCurrentPageId(currentPage.prev);
		}
	};

	const handleKeyDown = (event) => {
		if (event.key === 'ArrowRight') {
			goToNextPage();
		} else if (event.key === 'ArrowLeft') {
			goToPreviousPage();
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown);

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	});

	if (!currentPage) {
		return <div>Loading...</div>;
	}

	const updateCurrentPage = (updatedPage) => {
		setCurrentPage(updatedPage);
		setPages((prevPages) =>
			prevPages.map((page) => (page.id === updatedPage.id ? updatedPage : page))
		);
		// Also update the pages in the API module
		updatePage(updatedPage);
	};

	const createField = (moduleId, type, fields, options) => {
		//find max field id

	};


	function saveProduct(id, param2) {
		console.log("saveProduct", id, param2)
		const newProduct = { ...currentProduct, ...param2 };
		request("PUT", `/api/v1/product/${id}`, newProduct)
			.then((res) => {
				console.log("res", res)
				setCurrentProduct(res.data);
			})
			.catch((err) => {
				console.log("err", err)
			})
	}

	return (
		<div className="relative">
			<div className="absolute left-10 top-0">
				<EditableTextArea
					initialText={currentProduct.name ? currentProduct.name : ""}
					onSave={(t) => saveProduct(currentProduct.id, { name: t })}
					classes={"text-2xl inline-block uppercase text-black"}
					maxLength={30}
				/>
			</div>
			<NewPageIndicator currentPageId={currentPageId} setCurrentPageId={setCurrentPageId} pages={pages} pagesHead={pagesHead} onAddPage={handleAddPage}/>
			<div className="flex justify-center ">
				<button
					onClick={goToPreviousPage}
					disabled={!currentPage.prev}
					className="fixed left-2 top-1/2 hover:text-red-700 text-red-600 disabled:text-white "
					style={{fontSize: '50px', zIndex: 10 }}
				>
					&#x276E; {/* Left arrow */}
				</button>
				<button
					onClick={goToNextPage}
					disabled={!currentPage.next}
					className="fixed right-2 top-1/2 hover:text-red-700 text-red-600 disabled:text-white "
					style={{fontSize: '50px', zIndex: 10 }}
				>
					&#x276F; {/* Right arrow */}
				</button>
			</div>
			<div className="flex min-h-full px-7 relative">
				<Column
					modules={modules} setModules={setModules}
					pages={pages} setPages={setPages}
					column="left" setDragging={setDragging}
					headId={currentPage.leftHead}
					setHeadId={(newHeadId) => {
						updateCurrentPage({
							...currentPage,
							leftHead: newHeadId,
						});
					}}
					setOtherHead={(newHeadId) => {
						updateCurrentPage({
							...currentPage,
							rightHead: newHeadId,
						});
					}}
					masterModules={masterModules}
					currentPageId={currentPageId}
					dragging={dragging}
					productId={productId}
				/>
				<Column modules={modules} setModules={setModules}
						pages={pages} setPages={setPages}
						column="right" setDragging={setDragging}
						headId={currentPage.rightHead}
						setHeadId={(newHeadId) => {
							updateCurrentPage({
								...currentPage,
								rightHead: newHeadId,
							});
						}}
						setOtherHead={(newHeadId) => {
							updateCurrentPage({
								...currentPage,
								leftHead: newHeadId,
							});
						}}
						masterModules={masterModules}
						currentPageId={currentPageId}
						dragging={dragging}
						productId={productId}
				/>
				<BurnBarell modules={modules} setModules={setModules} dragging={dragging} setDragging={setDragging}
							leftHead={currentPage.leftHead} rightHead={currentPage.rightHead} setLeftHead={(newHeadId) => {
					setCurrentPage((prevPage) => ({
						...prevPage,
						leftHead: newHeadId,
					}));
				}} setRightHead={(newHeadId) => {
					setCurrentPage((prevPage) => ({
						...prevPage,
						rightHead: newHeadId,
					}));
				}}
				/>
				<MasterModules masterModules={masterModules} setMasterModules={setMasterModules} />
			</div>
		</div>

	);
}

const MasterModules = ({masterModules, setMasterModules}) => {

	const [collapsed, setCollapsed] = useState(true);

	const [filteredModules, setFilteredModules] = useState(masterModules);
	const [selectedModule, setSelectedModule] = useState(null);
	const [currentlyAdding, setCurrentlyAdding] = useState(null);

	const filterModules = (query) => {
		if (!query || query == "") return setFilteredModules(masterModules);
		setFilteredModules(masterModules.filter(module => module.content.title.includes(query)));
	}

	const handleDragStart = (e, module) => {
		setSelectedModule(module.id);
		e.dataTransfer.setData('moduleID', module.id);
		e.dataTransfer.setData('column', "master");
	}

	const handleAddMaster = (content) => {
		setCollapsed(false);
		const newMaster = addMaster(content);
		setMasterModules((masterModules) => {
			return [...masterModules, newMaster];
		});
		setCurrentlyAdding(newMaster.id)
	}

	useEffect( () => {
		emitter.on('createMaster', handleAddMaster);

		// Clean up the event listener on component unmount
		return () => {
			emitter.off('createMaster', handleAddMaster);
		};
	}, []);

	useEffect(() => {
		setFilteredModules(masterModules);
		filterModules("");
	}, [masterModules]);


	return(
		<div
			className={`fixed right-1 rounded text-white 
			text-center bg-blue-900 border border-blue-300
			transition-all ease-in-out overflow-hidden z-40
            p-1 ${collapsed ? 'w-8 h-8' : 'w-64 h-[89lvh]'}`}
			style={{ boxShadow: `inset 0 0 ${collapsed ? '10px' : '70px'} 5px rgba(100, 149, 237, 0.5)` }}
		>
			<div className="flex">
				<GiHamburgerMenu
					className="text-2xl"
					onClick={() => setCollapsed(!collapsed)}
				/>
				{!collapsed && <h1 className="ml-2 text-xl text-white text-center">Master Modules</h1>}
			</div>
			{!collapsed &&
				<div>
					<input
						type="text"
						className="text-black w-full h-7 rounded mb-2"
						defaultValue=""
						onChange={(e) => filterModules(e.target.value)}
					>
					</input>
					<div>
						{filteredModules.map(module => (
							<motion.div
								key={module.id}
								className="text-white border rounded animation-pop-in mt-1 first:mt-0"
								draggable="true"
								onClick={() => setSelectedModule(module.id)}
								onDragStart={(e) => handleDragStart(e, module)}
								{...(currentlyAdding === module.id
									? {
										initial: { opacity: 0, scale: 0.5 },
										animate: { opacity: 1, scale: 1 },
										transition: {
											duration: 0.5,
											delay: 0.3,
											ease: [0, 0.71, 0.2, 1.01],
										},
									}
									: {})}
								onAnimationComplete={() => {
									if (currentlyAdding === module.id) setCurrentlyAdding(null);
								}}
							>
								{ (selectedModule === module.id)
									? (
										<>
											<h1>{module.content.title}</h1>
											<h1>{module.content.text}</h1>
										</>
									)
									: (
										<h1>{module.content.title}</h1>
									)
								}
							</motion.div>
						))}
					</div>
				</div>
			}
		</div>
	)
}

const BurnBarell = ({modules, setModules, dragging, setDragging, leftHead, rightHead, setLeftHead, setRightHead}) => {
	const [active, setActive] = useState(false);

	const handleDragOver = (e) => {
		if (!dragging) return; // check that we are dragging a module, not something else
		e.preventDefault();
		setActive(true);
	}

	const handleDragLeave = (e) => {
		e.preventDefault();
		setActive(false);
	}

	const handleDrop = (e) => {
		e.preventDefault();
		setActive(false);
		const moduleID = e.dataTransfer.getData('moduleID');
		const module = modules.find(module => module.id === moduleID);
		setModules((modules) => modules.filter(module => module.id != moduleID));
		removeModule(moduleID);
		// check if the module is a head
		setDragging(false);
	}

	return ( dragging
		?<div
			onDragOver={handleDragOver}
			onDragLeave={handleDragLeave}
			onDrop={handleDrop}
			className={`mt-10 fixed bottom-2 left-1/2 transform -translate-x-1/2 -translate-y-2
			 grid h-20 w-20 shrink-0 place-content-center rounded-full border text-5xl transition-colors ${
				active 
					? "border-red-500 bg-red-800/20 text-red-500" : "border-gray-500 bg-gray-400/20 text-gray-500"}`}
			>
			{active
				? <FaFire className="animate-bounce"/>
				: <FiTrash />}
		</div>
		: <></>
	)
}

const DropIndicator = ({ beforeId, column, visible }) => {
	return (
		<div
			data-before={beforeId || "-1"}
			data-column={column}
			className={`my-0.5 h-1 w-full bg-gray-400 rounded transition-opacity ${visible ? "opacity-100" : "opacity-0"}`}>
		</div>
	);
}

const Column = ({ modules, setModules, pages, setPages, dragging, column, setDragging, headId, setHeadId, setOtherHead, masterModules, currentPageId, productId}) => {
	const [active, setActive] = useState(false);
	const [visibleDropIndicator, setVisibleDropIndicator] = useState(null);

	const handleDragStart = (e, module) => {
		e.dataTransfer.setData('moduleID', module.id);
		e.dataTransfer.setData('column', column);
		e.dataTransfer.setData('isHead', module.id == headId);
		e.dataTransfer.setData('isField', false);
		setDragging(true);
	}

	const saveModule = async (id, content) => {
		let module = modules.find(module => module.id === id);
		module.content = content;
		await updateModule(module);
		setModules((modules) => modules.map(module => module.id === id ? { ...module, content } : module));
	}

	const handleDragEnd = (e) => {
		setDragging(false);
	}

	const handleDragOver = (e) => {
		if (!dragging) return; // check that we are dragging a module, not something else
		e.preventDefault();
		highlightIndicator(e);
		setActive(true);
	}

	const highlightIndicator = (e) => {
		const indicators = getIndicators();
		clearHighlight(indicators);
		const el = getNearestIndicator(e, indicators);
		setVisibleDropIndicator(el.element.dataset.before)
		//if (el && el.element) {
		//	// Now it's safe to access classList
		//	el.element.classList.remove('!opacity-0'); // Start the transition to fully visible
		//	el.element.classList.add('!opacity-100');  // Apply the fully visible class
		//
		//	setTimeout(() => {
		//	}, 10);
		//}
	}

	const clearHighlight = (els) => {
		const indicator = els || getIndicators();

		setVisibleDropIndicator(null)
		//indicator.forEach(el => {
		//	el.classList.remove('!opacity-100');
		//	el.classList.add('!opacity-0');
		//});
	}

	const getIndicators = () => {
		return Array.from(document.querySelectorAll(
			`[data-column="${column}"]`
		));
	}

	const getNearestIndicator = (e, indicators) => {
		const DISTANCE_OFFSET = 100;
		const el = indicators.reduce(
			(closest, indicator) => {
				const box = indicator.getBoundingClientRect();
				const offset = e.clientY - (box.top + DISTANCE_OFFSET)
				if (offset < 0 && offset > closest.offset) {
					return { offset, element: indicator };
				} else {
					return closest;
				}
			},
			{
				offset: Number.NEGATIVE_INFINITY,
				element: indicators[indicators.length - 1]
			}
		);
		return el;
	}

	const handleDragLeave = (e) => {
		e.preventDefault();
		clearHighlight();
		setActive(false);
	}


	const handleDrop = async (e) => {
		e.preventDefault();
		clearHighlight();
		setActive(false);

		// Retrieve data from the drag event
		const moduleID = e.dataTransfer.getData('moduleID');
		const columnFrom = e.dataTransfer.getData('column');

		// Get the drop target and determine the position
		const indicators = getIndicators();
		const { element } = getNearestIndicator(e, indicators);

		const beforeId = element.dataset.before || "-1";

		// Avoid unnecessary operations if the module is dropped in the same place
		if (beforeId != moduleID || (beforeId == moduleID && columnFrom == "master")) {
			let copy = [...modules];

			let moduleToTransfer;

			// If the module is from the master list, create a new module
			if (columnFrom == "master") {
				const moduleContent = masterModules.find(module => module.id == moduleID).content;
				moduleToTransfer = createNewModule(moduleContent, column, null, currentPageId, productId);
				moduleToTransfer = await addModule(moduleToTransfer);
				copy.push(moduleToTransfer);
			} else {
				// Find the module to transfer in the current modules list
				moduleToTransfer = copy.find(module => module.id == moduleID);
				if (!moduleToTransfer) return;
			}

			// Update the column of the module
			if (columnFrom != column) {
				// update the module's column in the API
				moduleToTransfer = { ...moduleToTransfer, column };
				await updateModule(moduleToTransfer);
			}

			// Remove the module from its original position
			console.log("moduleToTransfer", moduleToTransfer)
			copy = copy.filter(module => module.id != moduleToTransfer.id);
			console.log("copy after filter", copy)

			// Reassign order values in the old column
			const oldColumnModules = copy
				.filter(module => module.column == columnFrom && module.pageId == currentPageId)
				.sort((a, b) => a.order - b.order);

			const updatedOldColumnModules = oldColumnModules.map((module, index) => {
				return { ...module, order: index };
			});

			// Update 'copy' with the updated old column modules
			copy = copy.map(module => {
				const updatedModule = updatedOldColumnModules.find(m => m.id == module.id);
				return updatedModule ? updatedModule : module;
			});

			// Now, insert moduleToTransfer into the new column modules
			const newColumnModules = copy
				.filter(module => module.column == column && module.page == currentPageId)
				.sort((a, b) => a.order - b.order);

			// Determine the new index for the module in the new column
			let newIndex;
			if (beforeId == "-1") {
				// Place at the end
				newIndex = newColumnModules.length;
			} else {
				const beforeIndex = newColumnModules.find(module => module.id == beforeId).order;
				newIndex = beforeIndex >= 0 ? beforeIndex : newColumnModules.length;
			}
			console.log("beforeId", beforeId)
			console.log("newIndex", newIndex)

			console.log("newColumnModules before splicing", newColumnModules);
			// Insert moduleToTransfer into newColumnModules at newIndex
			newColumnModules.splice(newIndex, 0, moduleToTransfer);
			console.log("newColumnModules after splicing", newColumnModules);

			// Reassign order values in the new column
			const updatedNewColumnModules = newColumnModules.map((module, index) => {
				if (module.id == moduleToTransfer.id) {
					copy.push(moduleToTransfer);
				}
				return { ...module, order: index };
			});

			// Update 'copy' with the updated new column modules
			console.log("copy before mapping", copy)
			copy = copy.map(module => {
				const updatedModule = updatedNewColumnModules.find(m => m.id == module.id);
				return updatedModule ? updatedModule : module;
			});
			console.log("copy after mapping", copy)

			// Collect all updated modules
			const modulesToUpdate = [...updatedOldColumnModules, ...updatedNewColumnModules];

			// Update the backend with the new order
			await onModulesReordered(updatedOldColumnModules, currentPageId, columnFrom);
			await onModulesReordered(updatedNewColumnModules, currentPageId, column);

			// Update the modules state with the updated modules
			setModules(copy);
			console.log("copy", copy)
			setDragging(false);
		}
	};


	const filteredModules = modules
		.filter(
			(module) =>
				module.column === column && module.page === currentPageId
		)
		.sort((a, b) => a.order - b.order);

	//useEffect(() => {
	//	console.log("filteredModules", filteredModules)
	//	filteredModules.forEach((module, index) => {
	//		if (module.order !== index) {
	//			module.order = index;
	//			updateModule(module);
	//		}
	//	});
	//}, [modules]);

	const renderModules = (filteredModules, headId) => {
		// Create a map for quick access to modules by their ID
		const moduleMap = filteredModules.reduce((acc, module) => {
			acc[module.id] = module;
			return acc;
		}, {});

		const orderedModules = filteredModules.sort((a, b) => a.order - b.order);

		// Render the modules in the correct order
		return orderedModules.map((module) => (
			<Module
				key={module.id}
				{...module}
				handleDragStart={handleDragStart}
				handleDragEnd={handleDragEnd}
				saveModule={saveModule}
				visibleDropIndicator={visibleDropIndicator}
			/>
		));
	};

	return (
		<div className="w-1/2 shrink-0 flex flex-col h-fit">
			<div
				className={`h-full min-h-10 w-full transition-colors ${active ? "bg-gray-100/50" : "bg-gray-100/0"}
				grid grid-cols-1 auto-rows-[44lvh] gap-0 grow`}
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
				onDrop={handleDrop}
			>
				{
					renderModules(filteredModules, headId)
				}
			</div>
			<DropIndicator column={column} beforeId="-1" visible={visibleDropIndicator == "-1"} />
			<AddModule column={column} modules={modules} setModules={setModules}
					   pages={pages} setPages={setPages} currentPageId={currentPageId} productId={productId}/>
		</div>
	);
}

const AddModule = ({ column, modules, setModules, pages, setPages, currentPageId, productId }) => {

	const pagesRef = useRef(pages);

	const handleAddModule = async () => {

		const prevPages = pagesRef.current;

		const currentPage = pages.find((page) => page.id === currentPageId);

		const content = { title: "New Module", text: "New Module Description", fields: [] };

		// Filter modules by column and current page
		const modulesInColumn = modules.filter(
			(module) => module.column == column && module.page == currentPageId
		);

		//find headModule using leftHead or rightHead
		const headModule = modulesInColumn.find((module) => module.id == currentPage[column + 'Head']);
		console.log("headModule", headModule)

		const order = modulesInColumn.length;

		// Create a new module
		let newModule = createNewModule(content, column, order, currentPageId, productId);
		newModule = await addModule(newModule); // This assigns a new ID and adds it to the API modules array

		//if (lastModule) {
		//	// Update lastModule's next pointer
		//	let updatedLastModule = { ...lastModule, next: newModule.id };
		//	//updatedLastModule = await updateModule(updatedLastModule); // Update in API
		//
		//	// Update in state
		//	setModules((prevModules) => prevModules.map(
		//		module => module.id === lastModule.id ? updatedLastModule : module
		//	));
		//}
		//else {
		//	// Column is empty; update page's head
		//	let updatedPage = { ...currentPage, [column + 'Head']: newModule.id };
		//	updatedPage = await updatePage(updatedPage);
		//
		//	// Update in state
		//	let updatedPages = prevPages.map((page) =>
		//		page.id === currentPageId ? updatedPage : page
		//	);
		//
		//	// Wait for all promises to resolve
		//	updatedPages = await Promise.all(updatedPages);
		//
		//	setPages(updatedPages); // Update in state
		//}

		console.log("newModule", newModule);
		console.log("Modules in column", modulesInColumn);

		// Add the new module to state
		setModules((prevModules) => [...prevModules, newModule]);
	};



	return (
		<motion.button
			layout
			onClick={handleAddModule}
			className="flex items-center justify-center w-full text-gray-500 hover:text-gray-700 gap-1.5 transition-colors px-3 text-s"
		>
			<FiPlus />
			<span>Add Module</span>
		</motion.button>
	);
};



const createNewModule = (content, column, order, pageId, productId) => {
	return {
		content,
		column,
		order,
		type: 'ListModule',
		page: pageId,
		product: productId,
	};
}

export default FormBuilder;

export { DropIndicator };
