import React, { useState, useContext } from 'react';
import { ArticleContext } from "../../../../App";
import ArticleSelect from "./ArticleSelect";
import DeleteButton from "./DeleteButton";
import InputText from './InputText'; // Ensure this is imported
import InputCheckbox from './InputCheckbox';
import InputTextArea from "./InputTextArea"; // Ensure this is imported
import Switch from "../../../../external_elements/js/Switch";

const SliderField = ({ onSubmit, fieldData, handleInputChange, deleteField}) => {
	const { articles, categories } = useContext(ArticleContext);
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [min, setMin] = useState(0);
	const [max, setMax] = useState(100);
	const [unit, setUnit] = useState('');
	const [showUnit, setShowUnit] = useState(false);
	const [isInterval, setIsInterval] = useState(false);

	const hasUnit = fieldData.options.unit != null



	const handleSubmit = () => {
		const options = {
			userFacingTitle: title,
			userFacingDescription: description,
			min: min,
			max: max,
			unit: showUnit ? unit : null,
		};
		const field = {
			name: 'art_'+options.userFacingTitle.toLocaleLowerCase().substring(0,4),
			type: 'slider,',
			options: JSON.stringify(options)
		}
		onSubmit(field);
	};

	const switchInterval = (value) => {
		setIsInterval(value)
		handleInputChange("isInterval", value)
	}

	return (
		<div className="p-4 relative">
			<DeleteButton deleteField={deleteField} />
			<div className="bg-white rounded-md shadow-md p-6">
				<h2 className="text-2xl mb-4">Slider</h2>

				<ArticleSelect
					articles={articles}
					categories={categories}
					handleInputChange={handleInputChange}
					articleId={fieldData.options.articleId}
				/>

				<InputText
					label="Title"
					type="text"
					value={fieldData.options.title}
					onChange={(e) => handleInputChange("title", e.target.value)}
				/>
				<InputTextArea
					label="Description"
					value={fieldData.options.description}
					onChange={(e) => handleInputChange("description", e.target.value)}
				/>
				<InputText
					label="Min Value"
					type="number"
					value={fieldData.options.min}
					onChange={(e) => handleInputChange("min", e.target.value)}
				/>
				<InputText
					label="Max Value"
					type="number"
					value={fieldData.options.max}
					onChange={(e) => handleInputChange("max", e.target.value)}
				/>
				<InputCheckbox
					label="Show Price"
					checked={fieldData.options.showPrice}
					onChange={() => handleInputChange("showPrice", !fieldData.options.showPrice)}
				/>
				<InputCheckbox
					label="Show Unit"
					checked={hasUnit}
					onChange={() => handleInputChange("unit", hasUnit ? null : "")}
				/>

				{hasUnit && (
					<InputText
						label="Unit"
						type="text"
						value={fieldData.options.unit}
						onChange={(e) => handleInputChange("unit", e.target.value)}
					/>
				)}


			</div>
		</div>
	);
};

export default SliderField;
