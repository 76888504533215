 import React, { useEffect, useState, useContext, useCallback } from 'react';
 import ArticleField from "./ArticleField";
 import axios from "axios";
 import SelectionField from "./SelectionField";
 import TextField from "./TextField";
 import SectionField from "./SectionField";
 import SliderField from "./SliderField";
 import { FieldsContext } from "../ProductCreation";
 import { debounce } from "lodash";
 import { ArticleContext } from "../../../../App";
 import { request } from "../../../../../axios_helper";




 const Field = ({ fieldDataInput }) => {

	 const type = fieldDataInput.type;

	 const { fields, setFields } = useContext(FieldsContext);
	 const { articles, categories} = useContext(ArticleContext)
	 const localFields = fields.filter(field => field.parentId == null);

	 const [fieldData, setFieldData] = useState(fieldDataInput)


	 const updateField = (newField) => {
		 const updatedFields = fields.map(field =>
			 field.id === newField.id ? newField : field
		 );

		 setFields(updatedFields);
	 };


	 const submit = async (field) => {
		 console.log("submitting");
		 await request("POST", "/api/v1/field", field);
	 }


	 const debouncedSave = useCallback(debounce((field) => {
		 submit(field);
	 }, 300), []);

	 const handleFieldChange = (updatedFieldOptions) => {
		 const updatedFieldData = {...fieldData, options: updatedFieldOptions}
		 console.log("Check for productId in updatedFieldData: ", updatedFieldData)
		 debouncedSave(updatedFieldData);
	 };

	 const handleInputChange = (property, newValue) => {
		 console.log("Pre-update section")
		 // Update the local state if you have one
		 const updatedOptions = { ...fieldData.options, [property]: newValue };
		 setFieldData(prevData => {
			 handleFieldChange(updatedOptions); // This calls handleFieldChange in the Field component
			 return { ...prevData, options: updatedOptions };
		 })
		 updateField(fieldData)
		 console.log("Post-update section")
	 };

	 const [allFields, setAllFields] = useState([]);



	 useEffect(() => {

		 request("GET", "/api/v1/field")
		 			 .then(response => {
						  const parsedFields = response.data.map(field => {
								if (field.options) {
									 try {
											 field.options = field.options;
									 } catch (e) {
											 console.error('Error parsing options:', e);
									 }
								}
						  })
						setAllFields(parsedFields);
					 })
	 }, []);

	 function deleteField() {
		 if(!window.confirm("Are you sure you want to delete this field?")) {
			 return;
		 }
		 request("DELETE", "/api/v1/field?id=" + fieldData.id)
		 .then(response => {
			 const updatedFields = fields.filter(f => f.id !== fieldData.id);
			 setFields(updatedFields);
		 })
	 }

	 const fieldComponentMap = {
		 "article": ArticleField,
		 "text": TextField,
		 "selection": SelectionField,
		 "section": SectionField,
		 "slider": SliderField
	 };

	 const FieldComponent = fieldComponentMap[type];
	 if (FieldComponent) {
		 return (
			 <div>
			 <FieldComponent handleInputChange={handleInputChange} onSubmit={submit} fieldData={fieldData} deleteField={deleteField} />
			 <button className="" onClick={() => deleteField(fieldData)}>Delete</button>
			 </div>
	 )
	 }
 };


 export default Field;
