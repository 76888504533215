import React from "react";

function ModuleDeleteButton ({ deleteField }) {
    return (
        <button
            className="absolute top-2 right-2 bg-red-400 text-white p-1 rounded h-6 w-6 flex items-center justify-center text-xs hover:bg-red-500"
            onClick={deleteField}
        >
            X
        </button>
    )
}

export default ModuleDeleteButton;