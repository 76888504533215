import React, { useCallback, useEffect, useState, useContext } from 'react';
import PageCreation from './PageCreation';
import { debounce } from "lodash";
import axios from "axios";
import { useParams } from "react-router-dom";
import { request } from "../../../../axios_helper";
import { useOnMountUnsafe } from "../../../../UseOnMountUnsafe";
import { ArticleContext } from "../../../App";



export const FieldsContext = React.createContext();

const  ProductCreation = ({ products, configuration, inputConfigurationData }) => {

	const { productId: paramId } = useParams();
	const [productId, setProductId] = useState(paramId);

	const [title, setTitle] = useState(configuration ? configuration.title : '');
	const [selectedProduct, setSelectedProduct] = useState({ name: "", countryId: 1, pages: [], fields: [] });
	const [pages, setPages] = useState([]);  // Initialize with one
	const [fields, setFields] = useState([])
	// empty page
	const [currentPageIndex, setCurrentPageIndex] = useState(0);
	const [countries, setCountries] = useState([])
	const [currentPage, setCurrentPage] = useState({}); // Initialize with one empty page
	const { articles, setArticles} = useContext(ArticleContext)

	useOnMountUnsafe(() => {
		request("GET", "api/v1/articleCountry/countries")
			.then(response => setCountries(response.data));
		fetchFields();
		if (!productId) {
			console.log("No product id");
			// Initial setup if no productId is provided
			createNewProduct();
		} else {
			console.log("Product id: " + productId);
			// Fetch product details if productId exists
			fetchProductDetails(productId);
		}
		//request("GET", "api/v1/articleCountry/articles")
		//	.then(response => {
		//		setArticles(response.data);
		//	});
	});

	const fetchFields = (productId) => {
		request("GET", `/api/v1/field`)
			.then(response => {
				console.log("Fields fetched", response.data);
				setFields(response.data);
			});
	}

	const fetchProductDetails = (productId) => {
		request("GET", `/api/v1/product/${productId}`)
			.then(response => {
				console.log("Product details fetched", response.data);
				const data = response.data;
				setSelectedProduct(data);
				if (data.pages && data.pages.length === 0) {
					console.log("No pages, adding a new page");
					addPage(data.pages);
				}
			});
	};

	const createNewProduct = () => {
		console.log("Creating a new product");
		const newProduct = { name: "", countryId: 1, pages: [], fields: [] };
		request("POST", "/api/v1/product", newProduct)
			.then(response => {
				console.log("Product created", response.data);
				setProductId(response.data.id); // This will trigger the useEffect again
				setSelectedProduct(response.data);
			});
	};


	const submit = async (product) => {
		if (productId) {
			console.log("Submitting product", product);
			request("PUT", `/api/v1/product/${productId}`, product)
				.then(response => {
					console.log("Product updated", response.data);
					setSelectedProduct(prevProduct => {
						return response.data;
					});
				});
		}
	};

	const debouncedSave = useCallback(debounce(submit, 300), []);

	const handleProductUpdate = (property, newValue) => {
		setSelectedProduct(prevProduct => {
			const updatedProduct = { ...prevProduct, [property]: newValue };
			debouncedSave(updatedProduct);
			return updatedProduct;
		});
	};

	const addPage = (pages) => {
		const newPage = { title: "", description: "", ["productId"]: productId, position: selectedProduct.pages.length };
		//const updatedPages = [...selectedProduct.pages, newPage];
		//handleProductUpdate("pages", updatedPages);
		//setCurrentPageIndex(newPage.position);
		request("POST", "/api/v1/page", newPage)
			.then(response => {
				console.log("Page added", response.data);
				const updatedPages = [...selectedProduct.pages, response.data];
				setSelectedProduct(prevProduct => ({
					...prevProduct,
					pages: updatedPages
				}));
				setCurrentPageIndex(response.data.position);
			});
	};

	useEffect(() => {
		if (selectedProduct.pages && selectedProduct.pages.length > 0) {
			const sortedPages = [...selectedProduct.pages].sort((a, b) => a.position - b.position);
			setCurrentPage(sortedPages[currentPageIndex]);
		} else {
			setCurrentPage({});
		}
	}, [selectedProduct, currentPageIndex]);

	function handlePageUpdate(updatedData) {
		console.log("Page updated", updatedData);
		const updatedPages = [...selectedProduct.pages];
		updatedPages[currentPageIndex] = updatedData;
		setSelectedProduct(prevProduct => ({
			...prevProduct,
			pages: updatedPages
		}));
	}

	const debouncedUpdate = debounce((updatedPages) => {
		setPages(updatedPages);
	}, 300); // 300ms debounce time

	const handleFieldChange = (pageIndex, fieldIndex, updatedField) => {
		const updatedPages = [...pages];
		updatedPages[pageIndex].fields[fieldIndex] = updatedField;
		debouncedUpdate(updatedPages);
	};

	const nextPage = () => {
		if (currentPageIndex < selectedProduct.pages.length - 1) {
			setCurrentPageIndex(currentPageIndex + 1);
		}
	};

	const prevPage = () => {
		if (currentPageIndex > 0) {
			setCurrentPageIndex(currentPageIndex - 1);
		}
	};

	const handleKeyDown = (event) => {
		if (event.key === 'ArrowRight') {
			nextPage();
		} else if (event.key === 'ArrowLeft') {
			prevPage();
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown);

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	});

	return (
		<div className="p-4">
			<FieldsContext.Provider value={{ fields , setFields }}>

				<div className="mb-4">
					<label className="block text-gray-700 font-medium">
						Product Name
						<input
							className="mt-2 block w-full rounded-md border p-2"
							type="text"
							value={selectedProduct.name}
							onChange={(e) => handleProductUpdate('name', e.target.value)}
						/>
					</label>
				</div>
				<div className="mb-4">
					<label className="block text-gray-700 font-medium">
						Product Description
						<textarea
							className="mt-2 block w-full rounded-md border p-2"
							value={selectedProduct.description}
							onChange={(e) => handleProductUpdate("description", e.target.value)}
						></textarea>
					</label>
				</div>
				<div className="mb-4">
					<select className="mt-2 block w-full rounded-md border p-2" value={selectedProduct.countryId} onChange={(e) => handleProductUpdate(	'countryId', e.target.value)}>
						{countries.map(country => (
							<option key={country.id} value={country.id}>
								{country.name}
							</option>
						))}
					</select>
				</div>

				<div className="mt-4 text-right">
					<button onClick={() => addPage(selectedProduct.pages)} className="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded">Add page</button>
				</div>
				<div className="relative">
					{/* Left navigation button */}
					<button
						className="fixed left-5 top-1/2 -translate-y-1/2 z-10 rounded-full flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white disabled:bg-gray-300"
						style={{ width: '50px', height: '50px', fontSize: '24px' }}
						disabled={currentPageIndex === 0}
						onClick={prevPage}>&#x2190;
					</button>
					{selectedProduct.pages && selectedProduct.pages.length > 0 && selectedProduct.pages[currentPageIndex] !== undefined &&
						<PageCreation pageDataInput={currentPage} productId={productId} onPageDataChange={handlePageUpdate} />
					}
					{/* Right navigation button */}
					<button
						className="fixed right-5 top-1/2 -translate-y-1/2 z-10 rounded-full flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white disabled:bg-gray-300"
						style={{ width: '50px', height: '50px', fontSize: '24px' }}
						disabled={currentPageIndex === selectedProduct.pages.length - 1}
						onClick={nextPage}>&#x2192;
					</button>
				</div>

				<div className="mt-4 text-right">
					<button onClick={() => addPage(selectedProduct.pages)} className="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded">Add page</button>
				</div>
			</FieldsContext.Provider>
		</div>
	);
};

export default ProductCreation;
