import { useState } from "react";
import { useOnMountUnsafe } from "../UseOnMountUnsafe";
import { request } from "../axios_helper";
import { Link } from "react-router-dom";

function UserList () {
  const [users, setUsers] = useState([])
	  useOnMountUnsafe(() => {
		request("GET", "/api/v1/account").then((response) => {
		  setUsers(response.data);
		});
	  });

  return (
	  <div className="p-2 bg-white font-source-sans-pro">
		  <h2 className="mb-4 text-3xl font-bold">
			  Nutzer
		  </h2>
		  <table className="min-w-full leading-normal font-source-sans-pro">
			  <thead>
			  	<tr>
					<th className="py-3 px-5 border-b border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
						First Name
					</th>
					<th className="py-3 px-5 border-b border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
						Last Name
					</th>
					<th className="py-3 px-5 border-b border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
						Email
					</th>
				</tr>
			  </thead>
			  <tbody>
			  	{users.map(user => (
				<tr key={user.id}
					className={`
					${user.id % 2 === 0 ? 'bg-white' : 'bg-gray-50'} 
				  `}>
					<td
						className="py-3 px-5 border-b text-sm">
						{user.firstName}
					</td>
					<td
						className="py-3 px-5 border-b text-sm">
						{user.lastName}
					</td>
					<td
						className="py-3 px-5 border-b text-sm">
						{user.email}
					</td>
				</tr>
				))}
			  </tbody>
		  </table>
		  <div className="bg-green-500 hover:bg-green-700 border-1 border-green-800 text-white font-bold py-2 px-4 rounded h-10 w-40 text-center m-2">
			  <Link to={"/a/invite"}>Invite new User</Link>
		  </div>
	  </div>
  )
}

export default UserList;
