import React from 'react';

function FieldModal({ show, onClose, onSubmit }) {
	if (!show) return null;

	const handleFieldSelection = (fieldType) => {
		onSubmit(fieldType);
		onClose();
	};

	return (
		<div className="fixed z-10 inset-0 flex items-center justify-center">
			<div className="bg-gray-700 opacity-75 absolute inset-0" onClick={onClose}></div>
			<div className="modal-content bg-white rounded p-4 z-20 relative">
				<h2 className="text-xl mb-2">Select Field Type</h2>

				<div className="flex flex-col">
					<button
						className="mb-2 bg-green-500 text-white px-4 py-2 rounded"
						onClick={() => handleFieldSelection('article')}
					>
						Article Field
					</button>

					<button
						className="mb-2 bg-green-500 text-white px-4 py-2 rounded"
						onClick={() => handleFieldSelection('text')}
					>
						Text Field
					</button>

					<button
						className="mb-2 bg-green-500 text-white px-4 py-2 rounded"
						onClick={() => handleFieldSelection('selection')}
					>
						Selection Field
					</button>

					<button
						className="mb-2 bg-green-500 text-white px-4 py-2 rounded"
						onClick={() => handleFieldSelection('slider')}
					>
						Slider Field
					</button>
				</div>

				<div className="flex justify-end mt-4">
					<button type="button" className="bg-gray-300 text-black px-4 py-2 rounded" onClick={onClose}>Cancel</button>
				</div>
			</div>
		</div>
	);
}

export default FieldModal;
