import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { deleteAuthToken, getAuthToken, isExpired, request } from "../axios_helper";

export const AuthContext = React.createContext({});

function AuthProvider ({ children }) {


	const [auth, setAuth] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {

		let isMounted = true;

		let new_auth;

		const newAuth = () => {
			let returnData = {};
			if (!isExpired()) {
				request("GET", "/api/v1/account/current")
					.then(response => {
						console.log("AuthProvider response: ", response.data)
						new_auth = { ...response.data, token: getAuthToken() };
					})
					.catch(error => {
						new_auth = {};
					})
					.finally(
						() => {
							if (isMounted) {
								setAuth(new_auth);
								setLoading(false);
							}
						}
					);
			} else {
				if (isMounted) {
					setAuth({});
					setLoading(false);
				}
			}
		}

		newAuth();

		console.log("AuthProvider: ", new_auth);

		return () => { isMounted = false; };
	} , []);

	const [currentUser, setCurrentUser] = useState(null);

	const nav = useNavigate();



	//const getUser =  () => {
	//	request("GET", "/api/v1/account/current")
	//		.then(response => {
	//			return response.data;
	//		})
	//		.catch(error => {
	//			return null;
	//		});
	//};

	function logout() {
		request("POST", "/api/v1/account/logout")
			.then(() => {
				deleteAuthToken();
				setAuth({});
				window.location.href = "/";
				console.log("Logged out");
			})
	}



	//useEffect(() => {
	//	if (currentUser === null) {
	//		nav('/login');
	//	}
	//}, [currentUser, nav]);


	// Render your AuthContext.Provider if currentUser is not null
	return (
		<div>
			<AuthContext.Provider value={{ currentUser, setCurrentUser, auth, setAuth, logout, loading }}>
				{loading
				? <div>Loading...</div>
				: children }
			</AuthContext.Provider>
		</div>
	);

}

export default AuthProvider;
