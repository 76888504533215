// source: https://levelup.gitconnected.com/build-an-accessible-switch-component-with-react-and-typescript-d455a405aaa
import React from 'react';

import '../css/switch.scss'


function Switch( props ) {

	const onChange = (event) => {
		props.onChange && props.onChange(event.target.checked);
	};

	const labelId = `label-${props.id}`;
	const descriptionId = `description-${props.id}`;

	const labelBy = labelId + ' ' + descriptionId;

	return (
		<label htmlFor={props.id} className="switch">
			<input
				id={props.id}
				type="checkbox"
				role="switch"
				data-on={props['data-on']}
				checked={props.isChecked}
				data-off={props['data-off']}
				onChange={onChange}
				aria-checked={props.isChecked}
				aria-labelledby={labelBy}
			/>
			<div className="switch-labels">
				<span id={labelId}>{props.label}</span>
				{props.description &&
					<p id={descriptionId}>{props.description}</p>
				}
			</div>
		</label>
	);
}

Switch.defaultProps = {
	"data-on": 'ON',
	"data-off": 'OFF'
}

export default Switch;
