//
import React, { useEffect, useState } from "react";

function ArticleSelect(props) {
  const { articles, categories, handleInputChange, articleId } = props;

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filteredArticles, setFilteredArticles] = useState(articles);
  const [selectedArticle, setSelectedArticle] = useState(null);


	useEffect(() => {
		if (selectedCategory) {
			const filtered = articles.filter(article => article.categoryId === selectedCategory);
			setFilteredArticles(filtered);
		} else {
			setFilteredArticles(articles);
		}
		setSelectedArticle(articles.find(article => article.id === articleId))
	}, [selectedCategory, articles]);

	useEffect(() => {
		if (articleId) {
			const article = articles.find(article => article.id === articleId);
			if (article) {
				setSelectedCategory(article.categoryId);
			}
		}
	}, [articleId]);


  return (
	  <div>
		  <div className="mb-4">
			  <label className="block text-gray-700 font-medium">
				  Choose a Category
				  <select className="mt-2 block w-full rounded-md border p-2" onChange={(e) =>
					  e.target.value === "" ? setSelectedCategory(null) :
						  setSelectedCategory(Number(e.target.value))}>
					  <option value="">No category</option>
					  {categories.map(category => (
						  <option value={category.id} key={category.id}>
							  {category.name}
						  </option>
					  ))}
				  </select>
			  </label>
		  </div>

		<div className="mb-4">
			<label className="block text-gray-700 font-medium">
				Choose an Article
				<select
					className="mt-2 block w-full rounded-md border p-2"
					value={articleId}
					onChange={(e) => handleInputChange("articleId", Number(e.target.value))}>
					{selectedArticle ?
						<option value={articleId}>
							{selectedArticle.description1}
						</option> :
						<option value="">Select an article</option>
					}
					{/*<option value={articleId}>*/}
					{/*	{filteredArticles.find(article => article.id === articleId).description1 || "Select an article"}*/}
					{/*</option>*/}
					{filteredArticles.map(article => (
						<option value={article.id} key={article.id}>
							{article.description1}
						</option>
					))}
				</select>
			</label>
		</div>
	  </div>
  );
}

export default ArticleSelect;
