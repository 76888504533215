// InputCheckbox.js
import React from 'react';

const InputCheckbox = ({ label, checked, onChange }) => (
    <div className="mb-4">
        <label className="flex items-center text-gray-700 font-medium">
            <input
                type="checkbox"
                className="mr-2"
                checked={checked}
                onChange={onChange} />
            {label}
        </label>
    </div>
);

export default InputCheckbox;
