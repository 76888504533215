
const SliderElementNew = ({ fieldData, sliderValue, handleInputChange }) => {
    const { title, minVal, maxVal, unit, intervalStep, stepCount } = fieldData.options;
    const mean = (minVal + maxVal) / 2;
    const handleSliderChange = (value) => {
        handleInputChange(value);
    }

    return (
        <div className="w-96">
            <div className=" p-2 rounded-lg w-1/2">
                {/* Display for min, max, and current value */}
                <div className="flex justify-between mb-1">
                    <span className="text-xs">{`${minVal}`}</span>
                    <span className="text-xs font-semibold">{sliderValue ? sliderValue : mean}{unit ? ` ${unit}` : ''}</span>
                    <span className="text-xs">{`${maxVal}`}</span>
                </div>
                {/* Slider input */}
                <div>
                    {!fieldData.options.isInterval ?
                        <input
                            type="range"
                            min={minVal}
                            max={maxVal}
                            value={sliderValue}
                            onChange={(e) => handleSliderChange(e.target.value)}
                            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                            aria-label={`${title} Slider`}
                        />
                        :
                        <input
                            type="range"
                            min={minVal}
                            max={
                                Number(minVal) + Number(intervalStep * stepCount)
                            }
                            step={intervalStep}
                            value={sliderValue}
                            onChange={(e) => handleSliderChange(Number(e.target.value))}
                            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                            aria-label={`${title} Slider`}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

export default SliderElementNew;