import axios from "axios";
import Cookies from "js-cookie";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json"


export const getAuthToken = () => {
	if (Cookies.get("lav_auth_token") === undefined || Cookies.get("lav_auth_token") === null) {
		return null;
	}
	return Cookies.get("lav_auth_token");
}

export const isExpired = () => {
	if (getAuthToken() === null || getAuthToken() === "null") {
		return true;
	}
	const token = getAuthToken();
	const payload = token.split(".")[1];
	const decodedPayload = atob(payload);
	const parsedPayload = JSON.parse(decodedPayload);
	const expiration = parsedPayload.exp;
	const now = new Date().getTime() / 1000;
	return now > expiration;
}

export const setAuthToken = (token) => {
	Cookies.set("lav_auth_token", token);
}

export const  deleteAuthToken = () => {
	Cookies.remove("lav_auth_token");
}

function handleTokenExpiration() {
	deleteAuthToken()
	console.log("HELLO")
	//window.location.href = '/';
}

export const request = (method, url, data, headers, responseType) => {

	if (headers === undefined || headers === null) {
		headers = {}
	}

	if (getAuthToken() !== null && getAuthToken() !== "null") {
		headers["Authorization"] = `Bearer ${getAuthToken()}`
		console.log("Added auth token to headers")
	}

	console.log("Sent " + JSON.stringify(data) + " to " + url + " with method " + method)

	return axios({
		method: method,
		headers: headers,
		url: url,
		data: data,
		responseType: responseType
	}).catch(error => {
		console.error("Error: " + error)
		console.error("Error response: " + JSON.stringify(error.response))
		if (error.response.status && error.response.status === 401) {
			handleTokenExpiration();
		}
		return
	});
}

