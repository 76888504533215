function DeleteModal({ show, onClose, onDelete }) {
	if (!show) return null;

	return (
		<div className="fixed z-10 inset-0 flex items-center justify-center">
			<div className="bg-gray-700 opacity-75 absolute inset-0" onClick={onClose}></div>
			<div className="modal-content bg-white rounded p-4 z-20 relative">
				<h2 className="text-xl mb-2">Confirm Deletion</h2>
				<p>Are you sure you want to delete this article?</p>
				<div className="flex justify-end mt-4">
					<button className="bg-red-500 text-white px-4 py-2 rounded mr-2" onClick={onDelete}>Delete</button>
					<button className="bg-gray-300 text-black px-4 py-2 rounded" onClick={onClose}>Cancel</button>
				</div>
			</div>
		</div>
	);
}

export default DeleteModal;
