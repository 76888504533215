import logo from '../logo.svg';
import '../App.css';
import ArticleForm from "./ArticleForm";
import React, { useEffect, useState } from "react";
import ArticleList from "./ArticleList";
import EditArticle from "./EditArticle";
import 'tailwindcss/tailwind.css';
import { Link, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ArticleField from "./form/configuration/create/field/ArticleField";
import Field from "./form/configuration/create/field/Field";
import CategoryList from "./CategoryList";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Configuration from "./form/configuration/display/Configuration";
import PageCreation from "./form/configuration/create/PageCreation";
import ProductCreation from "./form/configuration/create/ProductCreation";
import ProductList from "./ProductList";
import LandingPage from "./LandingPage";
import Main, { onAddArticle, onDeleteArticle, onDeleteCategory } from "./form/configuration/Main";
import Login from "./form/client/Login";
import UserWindow from "./form/client/UserWindow";
import { useOnMountUnsafe } from "../UseOnMountUnsafe";
import { request } from "../axios_helper";
import Configurations from "./form/client/Configurations";
import SelectProduct from "./form/client/SelectProduct";
import EditConfiguration from "./form/client/EditConfiguration";
import AccountPage from "./form/client/AccountPage";
import AcceptInvitation from "./AcceptInvitation";
import UserList from "./UserList";
import AdminInvitationForm from "./AdminInvitationForm";
import AuthProvider from "./AuthProvider";
import Unauthorized from "./Unauthorized";
import RequireAuth from "./RequireAuth";
import Missing from "./Missing";
import AdminAccountPage from "./AdminAccountPage";
import FormBuilder from "./form/configuration/create/FormBuilder";
import NewEditConfiguration from "./form/client/NewEditConfiguration";

export const ArticleContext = React.createContext();


function App() {

    const [articles, setArticles] = useState([]);
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedArticle, setSelectedArticle] = useState(null);

    const handleArticleAdded = (article) => {
        setArticles(prev => [...prev, article]);
    };

    const field_article = {
        type: 'article',
        options: {
            id: '123',
            title: 'Sample Article',
            description: 'This is a sample description.',
            price: 50.00
        }
    };

    const onAddArticle = () => {
        // Simulate adding a new article with random data or open a modal to get new data
        // In a real-world app, you might open a form here to collect the article information
        const newArticle = {
            id: articles.length + 1, // Simulated ID, usually this comes from the backend
            internalId: `${articles.length + 1}a`,
            name: `New Article ${articles.length + 1}`,
            categoryId: articles.length + 1
        };

        // Update the state to include the new article
        setArticles([...articles, newArticle]);
    };

    const onDeleteArticle = async (id) => {
        // do delete request here
        var requestOptions = new URLSearchParams({
            articleId: id
        });
        const response = await fetch('http://localhost:8080/api/v1/articleCountry/deleteArticle?' + requestOptions, {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json'
            }
        });

        const newArticles = articles.filter(article => article.id !== id);

        setArticles(newArticles);
    };

    const onDeleteCategory = async (id) => {
        // do delete request here
        var requestOptions = new URLSearchParams({
            categoryId: id
        });
        const response = await fetch('http://localhost:8080/api/v1/category?' + requestOptions, {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json'
            }
        });

        const newCategories = categories.filter(category => category.id !== id);

        setCategories(newCategories);
    };

  return (
        <DndProvider backend={HTML5Backend}>
          <ArticleContext.Provider value={{ articles, categories, setArticles, setCategories, products, setProducts }}>
                  <Router>
                    <AuthProvider>
                      <Routes>

                          {/* public routes*/}
                          <Route path="/login" element={<Login />} />
                          <Route path="/unauthorized" element={<Unauthorized />} />
                          <Route path="/" element={<LandingPage />} />

                            {/* admin routes*/}
                          <Route element={<RequireAuth allowedRoles={["ADMIN"]} />}>
                              <Route path="a/" element={<Main />} >
                                  <Route path="/a/article-list" element={
                                      <ArticleList articles={articles} onArticleClick={setSelectedArticle}
                                                   onAddArticle={onAddArticle} onDeleteArticle={onDeleteArticle}/>
                                  }/>
                                  <Route path="/a/product-list" element={
                                      <ProductList articles={articles} onArticleClick={setSelectedArticle}
                                                   onAddArticle={onAddArticle}/>
                                  }/>
                                  <Route path="/a/product/edit/:productId" element={<FormBuilder/>}/>
                                  <Route path="/a/product/add" element={<ProductCreation/>}/>
                                  <Route path="/a/category-list" element={
                                      <CategoryList categories={categories} onDeleteCategory={onDeleteCategory}/>
                                  }/>
                                  <Route path="/a/user-list" element={<UserList/>}/>
                                  <Route path="/a/account" element={
                                      <AdminAccountPage/>
                                  } />
                                  <Route path="/a/invite" element={<AdminInvitationForm/>}/>
                                  <Route path="/a/form-builder" element={<FormBuilder/>}/>
                              </Route>
                          </Route>

                            {/* client routes*/}
                          <Route element={<RequireAuth allowedRoles={["ADMIN", "DEALER", "CLINIC"]} />}>
                              <Route path="u/" element={<UserWindow />}>
                                  <Route path="/u/configurationsNew" element={
                                      <NewEditConfiguration />
                                  }/>
                                  <Route path="/u/configurations" element={
                                      <Configurations />
                                  }/>
                                  <Route path="/u/new-config" element={
                                      <SelectProduct />
                                  }/>
                                  <Route path="/u/configurations/:configurationId" element={
                                      <NewEditConfiguration />
                                  } />
                                  <Route path="/u/account" element={
                                      <AccountPage/>
                                  } />
                                  <Route path="/u/accept-invitation" element={
                                      <AcceptInvitation />
                                  } />
                              </Route>
                            </Route>

                          <Route path="choice" element={<LandingPage />} />

                          <Route path="*" element={<Missing />} />


                      </Routes>
                    </AuthProvider>
                </Router>
              </ArticleContext.Provider>
            <div className="fixed bottom-0 left-1 px-2 py-1 text-xs bg-white rounded bg-opacity-90">
                Built by <a href="http://www.lavender-tech.com" target="_blank" className="text-blue-600">Lavender Technologies</a>
            </div>
        </DndProvider>
  );
}

export default App;
