import React from 'react';

const ModuleInputText = ({ label, type, placeholder, value, onChange }) => (
    <div className="flex items-center">
        <label className="text-gray-700 font-medium  w-2/3">
            {label}
        </label>
        <input
            className="block w-full rounded-md border p-2"
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
        />
    </div>
);

export default ModuleInputText;
