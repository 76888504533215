import { useEffect, useState } from "react";
import DeleteModal from "./modals/DeleteModal";
import ArticleModal from "./modals/ArticleModal";
import axios from "axios";
import { useContext } from "react";
import { ArticleContext } from "./App";
import { request } from "../axios_helper";
import * as XLSX from "xlsx";
import ExcelModal from "./modals/ExcelModal";
import { useOnMountUnsafe } from "../UseOnMountUnsafe";

function ArticleList({ onArticleClick, onAddArticle, onDeleteArticle}) {

	const [selectedArticleId, setSelectedArticleId] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showArticleModal, setShowArticleModal] = useState(false);
	const [showExcelModal, setShowExcelModal] = useState(false);
	const [selectedArticle, setSelectedArticle] = useState(null);
	const [countries, setCountries] = useState([]);
	const [excelFile, setExcelFile] = useState(null);
	const [typeError, setTypeError] = useState(null);

	const { articles, categories, setArticles, setCategories, products, setProducts } = useContext(ArticleContext)

	useOnMountUnsafe(() => {
		request("GET", "/api/v1/articleCountry/countries").then((response) => {
			setCountries(response.data);
		});
		if (articles.length === 0) {
			request("GET", "/api/v1/articleCountry/articles").then((response) => {
				setArticles(response.data);
			});
		}
		if(categories.length === 0) {
			request("GET", "/api/v1/category").then((response) => {
				setCategories(response.data);
			});
		}
	});

	const handleArticleClick = (article) => {
		setSelectedArticleId(article.id);
	};

	const handleDeleteClick = (e, article) => {
		e.stopPropagation(); // Prevent the row from getting selected
		setSelectedArticleId(article.id);
		setShowDeleteModal(true);
	};

	const handleCloseDeleteModal = () => {
		setShowDeleteModal(false);
	};

	const handleDeleteConfirm = () => {
		request("DELETE", `/api/v1/articleCountry/deleteArticle?articleId=${selectedArticleId}`)
			.then((response) => {
				console.log("Article deleted");
			});
		const newArticles = articles.filter(article => article.id !== selectedArticleId);
		setArticles(newArticles);
		setShowDeleteModal(false);
	};

	const handleOpenModal = (article) => {
		setSelectedArticle(article);
		console.log("Article prop in List:", article);
		setShowArticleModal(true);
	};

	const handleCloseModal = () => {
		setSelectedArticle(null);
		setShowArticleModal(false);
	};

	const handleCloseExcelModal = () => {
		setShowExcelModal(false);
	}

	const handleExcelSubmit = (dataToSubmit, priceList, selectedCountry) => {
		//console.log("selectedCountry: ", selectedCountry);
		request("POST", "/api/v1/articleCountry/createArticles", dataToSubmit).then((response) => {
			const newArticles = response.data;
			setArticles([...articles, ...newArticles]);
			setShowExcelModal(false);
			console.log("New articles: ", newArticles)
			if (priceList) {
				//newArticles.map(article => {
				//	if(priceList.find(price => price.internalId == article.internalId) == undefined) {
				//		console.log("Article: ", article);
				//	}
				//	//console.log("Price: ", priceList.find(price => price.internalId == article.internalId).price);
				//})
				return request("POST", "/api/v1/articleCountry/createArticleCountries",
					newArticles.map(article => {
						return {
							articleId: article.id,
							countryId: selectedCountry,
							price: priceList.find(price => price.internalId == article.internalId).price
						}
					})
				).then((response) => {
					const newPrices = response.data;
					setProducts([...products, ...newPrices]);
				});
			}
		});
	}

	const handleSubmit = (formData, file) => {
		if (formData.id) {
			console.log("Form data: ", formData)
			const fileForm = new FormData();
			fileForm.append('image', file);
			request("PUT", `/api/v1/articleCountry/updateArticle`, formData)
				.then((response) => {
					const newArticles = articles.map((article) => {
						if (article.id === formData.id) {
							return response.data;
						}
						return article;
					});
					setArticles(newArticles);
					setShowArticleModal(false);
					if (file) {
						return request("POST", `/api/v1/articleCountry/image/${formData.id}`, fileForm, {
							"Content-Type": "multipart/form-data",
						})
							.then((response2) => {
								console.log("Image uploaded: ", response2.data);
							}
						);
					}
				});
		} else {
			request("POST", "/api/v1/articleCountry/createArticle", formData)
				.then((response) => {
					const newArticles = [...articles, response.data];
					console.log("received article: ", response.data);
					setArticles(newArticles);
					setShowArticleModal(false);
				});
		}
	};


	const handleExcelFile = (e) => {
		let file = e.target.files[0];
		const allowedTypes = [
			'application/vnd.ms-excel',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			'text/csv',
			'application/csv',
		];
		if (file && allowedTypes.includes(file.type)) {
			console.log("File:", file);
			console.log("File type:", file.type);
			setTypeError(null);
			const reader = new FileReader();
			reader.readAsBinaryString(file);
			reader.onload = (e) => {
				setExcelFile(e.target.result)
			}
		} else {
			console.log("No file selected");
			setTypeError("Please select an Excel file.");
			setExcelFile(null);
		}
	}

	return (
		<div className="p-2 bg-white font-source-sans-pro">
			<h2 className="mb-4 text-3xl font-bold">
				Artikel
			</h2>
			<table className="min-w-full leading-normal">
				<thead>
				<tr>
					<th className="py-3 px-5 border-b border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
						ID
					</th>
					<th className="py-3 px-5 border-b border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
						Internal ID
					</th>
					<th className="py-3 px-5 border-b border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
						Artikelbezeichnung 1
					</th>
					<th className="py-3 px-5 border-b border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
						Artikelbezeichnung 1
					</th>
					<th className="py-3 px-5 border-b border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
						Category ID
					</th>
					<th className="py-3 px-5 border-b border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
						Actions
					</th>
				</tr>
				</thead>
				<tbody>
				{articles.map((article, index) => (
					<tr
						key={index}
						className={`
                ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} 
                ${selectedArticleId === article.id ? 'bg-lavender' : ''}
              `}
						onClick={() => handleArticleClick(article)}
					>
						<td className="py-3 px-5 border-b text-sm">
							{article.id}
						</td>
						<td className="py-3 px-5 border-b text-sm">
							{article.internalId}
						</td>
						<td className="py-3 px-5 border-b text-sm">
							{article.description1}
						</td>
						<td className="py-3 px-5 border-b text-sm">
							{article.description2}
						</td>
						<td className="py-3 px-5 border-b text-sm">
							{article.categoryId}
						</td>
						<td className="py-3 px-5 border-b text-sm">
							<button
								className="text-blue-500 hover:text-blue-700 pr-12"
								onClick={() => handleOpenModal(article)}
							>
								Edit
							</button>
							<button className="bg-red-500 text-white px-4 py-2 rounded" onClick={(e) => handleDeleteClick(e, article)}>
								Delete
							</button>
						</td>
					</tr>
				))}
				</tbody>
			</table>
			<div className="flex justify-between p-4 space-x-3">
				<button
					className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded h-10 w-10"
					onClick={() => handleOpenModal(null)}
				>
					+
				</button>
				{/*<form onSubmit={onExcelSubmit}>*/}
				{/*	<div className="flex-col space-y-2">*/}
				{/*		<input type="file" required onChange={handleExcelFile} />*/}
				{/*		{typeError && <p className="text-red-500">{typeError}</p>}*/}
				{/*	</div>*/}
				{/*	<button type="submit" className="bg-green-500 p-3 rounded">Upload Excel</button>*/}
				{/*</form>*/}
				<button
					className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded h-10 w-40"
					onClick={() => setShowExcelModal(true)}
				>
					Upload Excel
				</button>
			</div>
			<DeleteModal
				show={showDeleteModal}
				onClose={handleCloseDeleteModal}
				onDelete={handleDeleteConfirm}
			/>

			<ArticleModal
				show={showArticleModal}
				onClose={handleCloseModal}
				onSubmit={handleSubmit}
				article={selectedArticle}
				categories={categories}
			/>

			<ExcelModal show={showExcelModal} onClose={handleCloseExcelModal} onSubmit={handleExcelSubmit} countries={countries} />

		</div>
	);
}

export default ArticleList;
