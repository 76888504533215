import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
	const { auth, loading }  = useAuth();
	const location = useLocation();

	return (
		auth && !loading && allowedRoles.includes(auth?.role) //changed from user to accessToken to persist login
			// after refresh
			? <Outlet />
			: auth?.token //changed from user to accessToken to persist login after refresh
				? <Navigate to="/unauthorized" state={{ from: location }} replace />
				: <Navigate to="/login" state={{ from: location }} replace />
	);
}

export default RequireAuth;
