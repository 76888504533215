import { useState } from "react";
import { request } from "../axios_helper";
import { useOnMountUnsafe } from "../UseOnMountUnsafe";
import DeleteModal from "./modals/DeleteModal";

function AdminInvitationForm() {

	const [countries, setCountries] = useState([]);
	const [roles, setRoles] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [email, setEmail] = useState("");

	useOnMountUnsafe(() => {
		console.log("hostname: ", window.location.hostname);
		request("GET", "api/v1/articleCountry/countries")
			.then(response => setCountries(response.data));
		request("GET", "api/v1/account/roles")
			.then(response => setRoles(response.data));
	});

	const handleSubmit = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.target);
		const email = formData.get('email');
		const country = formData.get('country');
		const role = formData.get('role');
		const data = {
			email: email,
			countryId: country,
			role: role
		};
		// Call backend to create invitation
		request("POST", "api/v1/auth/invite", data)
			.then(response => {
				if (response.status === 200 && response.data.email === email) {
					// Show success message
					alert("Invitation sent");
					// open email client
					sendEmail(email, response.data.token);
				}
			});
	};

	function sendEmail(email, token) {
		const url = "localhost:3000/u/accept-invitation?token=" + token;
		const subject = "Einladung zum SKS Konfigurator";
		const newLine = "%0D%0A";
		const body = `Sehr geehrter Benutzer ${newLine}${newLine} Sie wurden eingeladen, dem SKS-Konfigurator beizutreten. Bitte klicken Sie
		 auf den folgenden Link, um die Einladung zu akzeptieren. ${newLine}${newLine} ${url} ${newLine}${newLine} Mit freundlichen Grüßen,${newLine}SKS-Team`;
		window.open(`mailto:${email}?subject=${subject}&body=${body}`);
	}

	return (
		<div className="font-source-sans-pro">
			<h2 className="text-2xl mb-4">Invite Admin</h2>
			<form onSubmit={handleSubmit}>
				<label className="block mb-2">Email</label>
				<input name="email" type="email" placeholder="User Email" onChange={(e) => setEmail(e.target.value)} className="mb-2 block rounded-md border p-2" required />
				<label className="block mb-2">Country</label>
				<select name="country" type="text" className="mb-2 block rounded-md border p-2" >
					{countries.map(country => (
						<option key={country.id} value={country.id}>
							{country.name}
						</option>
					))}
				</select>
				<label className="block mb-2">Role</label>
				<select
					name="role"
					className="mb-4 block rounded-md border p-2"
				>
					{roles.map(role => (
						<option key={role}
							value={role}>
							{role}
						</option>
					))}
				</select>
				<div>
					<button type="button" onClick={() => setShowModal(true)} className="bg-green-500 text-white px-4 py-2 rounded mr-2 mt-2">Send Invitation</button>
				</div>

				{showModal && (
					<div className="fixed z-10 inset-0 flex items-center justify-center">
						<div className="bg-gray-700 opacity-75 absolute inset-0" onClick={() => setShowModal(false)}></div>
						<div className="modal-content bg-white rounded p-4 z-20 relative">
							<h2 className="text-xl mb-2">Confirm Invitation</h2>
							<p>Are you sure you want to send this invitation to </p>
							<p className="font-bold">{email}</p>
							<p>?</p>
							<div className="flex justify-end mt-4">
								<button type="submit" className="bg-green-500 text-white px-4 py-2 rounded mr-2">Send</button>
								<button className="bg-gray-300 text-black px-4 py-2 rounded" onClick={() => setShowModal(false)}>Cancel</button>
							</div>
						</div>
					</div>)}
			</form>
		</div>
	);
}

export default AdminInvitationForm;
